import React from 'react';
import Service from '../api/Service';
import { Link, useParams } from 'react-router-dom';
import SiteCheck from './SiteCheck';
import TimeUtils from '../utils/TimeUtils';
import {Auth} from "aws-amplify";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip as ChartJSTooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  ChartJSTooltip
);

async function isSignedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

class YearlyResultsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      context: props.context,
      loaded: false,
      results: [],
      date: props.date,
      data: null,
      signed: null,
      checks_by_month_and_site: {},
    };

    if(props.context) {
      props.context.setContext('YearlyResultsComponent', this)
    }
  }

  isHashEmpty(obj) {
    for (var o in obj)
      if (o) return false;
    return true;
  }


  componentDidMount() {
    let date = this.state.date;
    if(date === 'this-year') {
      let d = new Date();
      date = d.getUTCFullYear()
    }

    isSignedIn().then( (signed) => {
      if (signed) {
        Auth.currentSession().then(session => {
            Service.getYearlyResults(date, session.getIdToken().getJwtToken()).then((response) => {
              if (this.isHashEmpty(response.data)) {
                this.setState({
                  loaded: true,
                  signed: true
                });
              } else {

                let region = Object.keys(response.data)[0];
                let results = response.data[region];
                let sites = Object.keys(results)

                let site = sites[0]
                if (this.state.context && this.state.context.getContext('SelectedSite')) {
                  if (sites.includes(this.state.context.getContext('SelectedSite'))) {
                    site = this.state.context.getContext('SelectedSite')
                  }
                }

                let schema = site.split(':')[0]
                let host_and_port = site.split('/')[2]
                let host = host_and_port.split(':')[0]
                let port = host_and_port.split(':')[1]

                // console.log("set init " + site)

                this.setState({
                  loaded: true,
                  signed: true,
                  data: response.data,
                  site: site,
                  schema: schema,
                  host: host,
                  port: port,
                  sites: sites,
                  checks_by_month_and_site: {}
                });
              }
            });
          }
        )
      } else {
        Service.getYearlyResults(date).then((response) => {
          if (this.isHashEmpty(response.data)) {
            this.setState({
              loaded: true,
              signed: false
            });
          } else {
            let region = Object.keys(response.data)[0];
            let results = response.data[region];
            let sites = Object.keys(results)

            let site = sites[0]
            if (this.state.context && this.state.context.getContext('SelectedSite')) {
              if (sites.includes(this.state.context.getContext('SelectedSite'))) {
                site = this.state.context.getContext('SelectedSite')
              }
            }

            let schema = site.split(':')[0]
            let host_and_port = site.split('/')[2]
            let host = host_and_port.split(':')[0]
            let port = host_and_port.split(':')[1]

            // console.log("set init " + site)

            this.setState({
              loaded: true,
              signed: false,
              data: response.data,
              site: site,
              schema: schema,
              host: host,
              port: port,
              sites: sites,
              checks_by_month_and_site: {}
            });
          }
        });
      }
    });
  }

  getYear() {
    let date = new Date();
    if (this.state.date && this.state.date !== 'this-year') {
      date = new Date(this.state.date + '-01-01T00:00:00Z');
    }
    return date.getUTCFullYear();
  }

  getChecksByMonth(month, site = null) {

    if (site === null) {
      site = this.state.site
    }

    if (this.state.checks_by_month_and_site !== null) {
      if (site in this.state.checks_by_month_and_site) {
        if (month in this.state.checks_by_month_and_site[site]) {
          return this.state.checks_by_month_and_site[site][month];
        }
      }
    }

    let year = this.getYear();
    let days = TimeUtils.getNumberOfDaysInMonth(year, month);
    // console.log(year);
    // console.log(month);

    let enchanced_monthly_checks = [];

    let regions = Object.keys(this.state.data);
    regions.forEach((region) => {
      let sites = this.state.data[region];
      let results = sites[site];

      let monthly_checks = results.filter(
        (result) =>
          result.time >= year + '-' + ('00' + (month + 1)).slice(-2) + '-' &&
          result.time < year + '-' + ('00' + (month + 2)).slice(-2) + '-'
      );

      let check_number = 0;
      for (let i = 0; i < days; i++) {
        let start_date =
          year +
          '-' +
          ('00' + (month + 1)).slice(-2) +
          '-' +
          ('00' + (i + 1)).slice(-2);
        let end_date =
          year +
          '-' +
          ('00' + (month + 1)).slice(-2) +
          '-' +
          ('00' + (i + 2)).slice(-2);

        while (
          check_number < monthly_checks.length &&
          monthly_checks[check_number].time < start_date
        ) {
          let check = {
            date: monthly_checks[check_number].time,
            errors: monthly_checks[check_number].errors,
            label:
              region +
              ' ' +
              monthly_checks[check_number].time
                .replace('-', '/')
                .replace('-', '/')
          };
          this.addCheck(enchanced_monthly_checks, i, check);
          // enchanced_monthly_checks.push(monthly_checks[check_number])
          check_number += 1;
        }
        let found = false;
        while (
          check_number < monthly_checks.length &&
          monthly_checks[check_number].time >= start_date &&
          monthly_checks[check_number].time < end_date
        ) {
          let check = {
            date: monthly_checks[check_number].time,
            errors: monthly_checks[check_number].errors,
            label:
              region +
              ' ' +
              monthly_checks[check_number].time
                .replace('-', '/')
                .replace('-', '/')
          };
          this.addCheck(enchanced_monthly_checks, i, check);
          // enchanced_monthly_checks.push(monthly_checks[check_number])
          check_number += 1;
          found = true;
        }
        if (!found && end_date < TimeUtils.getDate(new Date())) {
          // console.log('missed');
          let missed_check = {
            date: start_date,
            errors: { missed: 1 },
            label: region + ' ' + start_date.replace('-', '/').replace('-', '/')
          };
          this.addCheck(enchanced_monthly_checks, i, missed_check);
        }
      }
    });

    // console.log(enchanced_monthly_checks);

    let checks_by_month_and_site = this.state.checks_by_month_and_site
    if (!(site in checks_by_month_and_site)) {
      checks_by_month_and_site[site] = {}
    }
    checks_by_month_and_site[site][month] = enchanced_monthly_checks
    this.setState({checks_by_month_and_site: checks_by_month_and_site})

    return enchanced_monthly_checks;
  }

  addCheck(checks, position, check) {
    if (checks[position]) {
      checks[position].push(check);
    } else {
      checks[position] = [check];
    }
  }

  loadSite(site) {
    TimeUtils.months.map((month) => (this.getChecksByMonth(month, site)))
    this.setState({loaded: true});
  }

  handleSiteChange(e) {
    let site = e.target.value
    if(this.state.context) {
      this.state.context.setContext('SelectedSite', site)
    }

    let schema = site.split(':')[0]
    let host_and_port = site.split('/')[2]
    let host = host_and_port.split(':')[0]
    let port = host_and_port.split(':')[1]

    // console.log("set " + site)
    this.setState({loaded: false, site: site, schema: schema, host: host, port: port});

    setTimeout(() => (this.loadSite(site)), 0)
  }

  getChartOptions() {

    let theme = "light";
    if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
      theme = "dark";
    }

    let options = {
      responsive: true,
      scales: {
        y: {
          grid:{
            color: theme === 'dark' ? "rgb(26,26,26)" : "rgb(219,219,219)",
          },
          ticks: {
            color: "rgb(128,128,128)",
          },
          min: 0
        },
        x: {
          grid:{
            color: theme === 'dark' ? "rgb(26,26,26)" : "rgb(219,219,219)",
          },
          ticks: {
            color: "rgb(128,128,128)",
          }
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyleWidth: 17
          }
        },
        title: {
          display: true,
          text: '',
        },
        tooltip: {
          backgroundColor: "rgb(200,200,200)",
          borderColor: "rgb(229, 231, 235)",
          borderWidth: 1,
          bodyColor: "rgb(0,0,0)",
          titleColor: "rgb(0,0,0)",
        }
      },
    };
    return options
  }

  getChartData() {
    let labels = TimeUtils.months

    let error_types = [
      'bad-url',
      'unknown-scheme',
      'not-ok',
      'failed-ssl',
      'failed-cert',
      'future-cert',
      'past-cert',
      'expiring-cert',
      'expired-domain',
      'expiring-domain',
      'missed',
      'pending',
      'ok']

    let colors = [
      'rgb(218,70,241)',
      'rgb(166,40,185)',
      'rgb(255,6,6)',
      'rgb(255, 99, 132)',
      'rgb(197,44,154)',
      'rgb(236,155,48)',
      'rgb(196,22,58)',
      'rgb(225,117,59)',
      'rgb(255,82,58)',
      'rgb(225,217,59)',
      'rgb(143,143,143)',
      'rgb(62,107,220)',
      'rgb(109,206,28)']

    let errors_by_month = TimeUtils.months.map((month) => (
      this.getChecksByMonth(month).reduce((a,c) => a.concat(c), []).map((checks) => (checks['errors']))
    ))

    let datasets = []
    for (let index = 0; index < error_types.length; ++index) {
      const error_type = error_types[index];

      let d = errors_by_month.map((errors) => errors.reduce((a,c) => a + (c[error_type] ? c[error_type] : 0 ),0))

      if (!d.every(item => item === 0)) {
        if (this.props.params.date === 'this-year') {
          d = d.slice(0, new Date().getUTCMonth() + 1)
        }

        datasets.push({
          label: error_type,
          data: d,
          borderColor: colors[index],
          backgroundColor: colors[index],
        })
      }
    }

    let data = {
      labels,
      datasets: datasets
    };

    return data
  }

  render() {
    if (this.state.loaded) {
      if (this.state.data == null) {
        return (
          <div>
            <h1 className="font-semibold text-gray-900 black:text-gray-100 pb-8">
              <h4 className="font-bold text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4 pt-2 md:pt-16 pb-8">
                Annual&nbsp;health&nbsp;dashboard
              </h4>
              {TimeUtils.getYearLabel(this.getYear())}
            </h1>
            {(this.state.date === 'this-year') ?
              <div className="bg-blue-100 rounded-lg py-5 px-6 text-base text-blue-700 dark:text-blue-300" role="alert">
                No data is collected yet.
                Please double check that sites to monitor are added in
                <Link
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 pl-1 underline"
                  to="/settings">Settings</Link>.
              </div>
              :
              <div className="bg-blue-100 rounded-lg py-5 px-6 text-base text-blue-700 dark:text-blue-300" role="alert">
                No data is collected for the requested period.
              </div>
            }
          </div>
        );
      } else {
        return (
          <div>
            <h1 className="font-semibold text-gray-900 dark:text-gray-100 pb-5">
              <h4 className="font-bold text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4 pt-2 md:pt-16 pb-8">
                Annual&nbsp;health&nbsp;dashboard {this.state.signed === false ? <span className="text-red-500 dark:text-red-500 align-super text-lg">[demo]</span> : ""}
              </h4>
              {TimeUtils.getYearLabel(this.getYear())} &nbsp;&nbsp;
              <div className="inline-block w-80">
                <div className="mb-3 w-[256px] md:w-96 arrow">
                  <select value={this.state.site}
                          onChange={(e) => this.handleSiteChange(e)}
                          className="cursor-pointer form-select appearance-none block w-full px-3 pr-8 py-1.5 text-base font-normal text-gray-700 dark:text-gray-300 bg-white dark:bg-black
                                   bg-clip-padding bg-no-repeat border border-solid border-gray-300 dark:border-gray-400 rounded transition ease-in-out m-0
                                   focus:text-gray-700 dark:focus:text-gray-300 focus:bg-white dark:focus:bg-black focus:border-blue-600 dark:focus:border-blue-400 focus:outline-none"
                          aria-label="Select domain">
                    {this.state.sites.map((site) => <option value={site}>{site}</option>)}
                  </select>
                </div>
              </div>
            </h1>
            {this.state.signed === false ?
              <div className="bg-blue-100 dark:bg-blue-900 rounded-lg py-5 px-6 mb-2 text-base text-blue-700 dark:text-blue-300 mb-3" role="alert">
                You are not signed in.<br/>
                This is dashboard for default set of sites.
              </div> : ""
            }
            <div className="rounded-xl border border-gray-100 dark:border-gray-600 bg-white dark:bg-black px-5 pt-0 pb-0 flex overflow-x-auto">
              <div className="flex-shrink-0 w-full">
                {TimeUtils.months.map((month) => (
                  <div className={"flex font-sans bg-white dark:bg-black text-black dark:text-white p-4 px-6 pt-2 pb-0 gap-2 text-sm leading-6 " + (month === 11 ? " " : " border-b ") + "border-neutral-200 dark:border-neutral-800"}>
                    <div className="inline-block px-0 pb-1">
                      <pre>{TimeUtils.getMonthName(month)}</pre>
                    </div>
                    <div className="inline-block px-6">
                      {this.getChecksByMonth(month).map((checks) => (
                        <Link to={'/dashboard/daily/' + checks[0].date}>
                          <SiteCheck data={checks}/>
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="rounded-xl border border-gray-100 dark:border-gray-600 bg-white dark:bg-black px-5 my-3 pt-5 pb-5 flex overflow-x-auto">
              <div className="w-[850px] h-[425px] min-w-[850px] min-h-[425px]">
                <Line options={this.getChartOptions()} data={this.getChartData()}
                      className="w-[850px] h-[425px] min-w-[850px] min-h-[425px]"/>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <h1 className="font-semibold text-gray-900 dark:text-gray-100 pb-8">
            <h4 className="font-bold text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4 pt-2 md:pt-16 pb-8">
              Annual&nbsp;health&nbsp;dashboard
            </h4>
            {TimeUtils.getYearLabel(this.getYear())} &nbsp;&nbsp;
            { (this.state.site && this.state.sites) ?
            <div className="inline-block w-80">
              <div className="mb-3 w-[256px] md:w-96 arrow">
                <select value={this.state.site}
                        onChange={(e) => this.handleSiteChange(e)}
                        className="cursor-pointer form-select appearance-none block w-full px-3 pr-8 py-1.5 text-base font-normal text-gray-700 dark:text-gray-300 bg-white dark:bg-black
                                   bg-clip-padding bg-no-repeat border border-solid border-gray-300 dark:border-gray-400 rounded transition ease-in-out m-0
                                   focus:text-gray-700 dark:focus:text-gray-300 focus:bg-white dark:focus:bg-black focus:border-blue-600 dark:focus:border-blue-400 focus:outline-none"
                        aria-label="Select domain">
                  {this.state.sites.map((site) => <option value={site}>{site}</option>)}
                </select>
              </div>
            </div>: ""
            }
          </h1>
          <div className="pt-2 rounded-xl border border-gray-100 dark:border-gray-600 bg-white dark:bg-black p-0 px-5 pt-0 pb-0">
            <div className="m-4 spinner-border animate-spin border-blue-500 inline-block w-8 h-8 border-4 rounded-full border-t-transparent" role="status"/>
          </div>
        </div>
      );
    }
  }
}

export default (props) => (
  <YearlyResultsComponent {...props} params={useParams()} />
);
