class TimeUtils {

  constructor() {
    this.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,21, 22, 23];
    this.months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    this.monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
  }

  // Input example: 8 - in [0-11] range
  // Output example: "September"
  getMonthName(month) {
    return this.monthNames[month].slice(0,3)
  }

  getNumberOfDaysInMonth(year, month) {
    // Somehow it works if month is 0, 12, 13, etc. too
    return new Date(year, month + 1, 0).getDate();
  }


  // Input example: 8 - in [0-23] range
  // Output example: "08:00 - 09:00 UTC (00:00 - 01:00 PDT)"
  getHourLabel(utcHour) {
    let utcLabel =
      ('00' + utcHour).slice(-2) +
      ':00-' +
      ('00' + (utcHour + 1 === 24 ? 0 : utcHour + 1)).slice(-2) +
      ':00 UTC';

    let localTimeZone = new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2];
    let localHourStart = new Date('01/01/2000 ' + utcHour + ':00 UTC')
      .toString()
      .split(' ')[4]
      .slice(0, 5);

    let localHourEnd = null;
    if (utcHour + 1 !== 24) {
      localHourEnd = new Date('01/01/2000 ' + (utcHour + 1) + ':00 UTC')
        .toString()
        .split(' ')[4]
        .slice(0, 5);
    } else {
      localHourEnd = new Date('01/02/2000 00:00 UTC')
        .toString()
        .split(' ')[4]
        .slice(0, 5);
    }
    let localLabel =
      localHourStart + '-' + localHourEnd + ' ' + localTimeZone;

    return utcLabel + ' (' + localLabel + ')';
  }

  // // Input example: 8 - in [0-23] range
  // // Output example: "08:00 - 09:00 UTC (00:00 - 01:00 PDT)"
  // getShortHourLabel(utcHour) {
  //   let utcLabel =
  //     ('00' + utcHour).slice(-2) +
  //     ' - ' +
  //     ('00' + (utcHour + 1 === 24 ? 0 : utcHour + 1)).slice(-2);
  //
  //   return utcLabel;
  // }

  // Input example: 8 - in [0-23] range
  // Output example: "<pre>12 am</pre>"
  getShortHourLabel(utcHour) {
    let utcLabel = null
    if (utcHour === 0) {
      utcLabel = <pre>12 am</pre>
    } else if (utcHour < 10) {
      utcLabel = <pre> {utcHour} am</pre>
    } else if (utcHour < 12) {
      utcLabel = <pre>{utcHour} am</pre>
    } else if (utcHour === 12) {
      utcLabel = <pre>12 pm</pre>
    } else if (utcHour < 22) {
      utcLabel = <pre> {(utcHour - 12)} pm</pre>
    } else if (utcHour < 24) {
      utcLabel = <pre>{(utcHour - 12)} pm</pre>
    }

    return utcLabel;
  }


  // Input example: 1648379822 - seconds from 0 in the past
  // Output example: "11:17:02 UTC (04:17:02 PDT)"
  getTimeLabel(utcTimestamp) {
    let date = new Date(utcTimestamp * 1000);

    let utcLabel =
      ('00' + date.getUTCHours()).slice(-2) +
      ':' +
      ('00' + date.getUTCMinutes()).slice(-2) +
      ':' +
      ('00' + date.getUTCSeconds()).slice(-2) +
      ' UTC';
    let localTimeZone = new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2];
    let localLabel =
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2) +
      ':' +
      ('00' + date.getSeconds()).slice(-2) +
      ' ' +
      localTimeZone;

    return utcLabel + ' (' + localLabel + ')';
  }

  // Input example: new Date()
  // Output example: "2022/03/27 UTC"
  getDateLabel(utcDate) {
    let utcLabel =
      utcDate.getUTCFullYear() +
      '/' +
      ('00' + (utcDate.getUTCMonth() + 1)).slice(-2) +
      '/' +
      ('00' + utcDate.getUTCDate()).slice(-2) +
      ' UTC';

    return utcLabel;
  }

  // Input example: 2022
  // Output example: "2022 UTC"
  getYearLabel(utcYear) {
    let utcLabel = utcYear + ' UTC';

    return utcLabel;
  }

  // Input example: new Date()
  // Output example: "2022-03-27"
  getDate(date) {
    return (
      date.getUTCFullYear() +
      '-' +
      ('00' + (date.getUTCMonth() + 1)).slice(-2) +
      '-' +
      ('00' + date.getUTCDate()).slice(-2)
    );
  }

  parseDate(date) {
    let numericKeys = [ 1, 4, 5, 6, 7, 10, 11 ];
    let timestamp, struct, minutesOffset = 0;

    // ES5 §15.9.4.2 states that the string should attempt to be parsed as a Date Time String Format string
    // before falling back to any implementation-specific date parsing, so that’s what we do, even if native
    // implementations could be faster
    //              1 YYYY                2 MM       3 DD           4 HH    5 mm       6 ss        7 msec        8 Z 9 ±    10 tzHH    11 tzmm
    if ((struct = /^(\d{4}|[+\-]\d{6})(?:-(\d{2})(?:-(\d{2}))?)?(?:T(\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{3}))?)?(?:(Z)|([+\-])(\d{2})(?::(\d{2}))?)?)?$/.exec(date))) {
      // avoid NaN timestamps caused by “undefined” values being passed to Date.UTC
      for (var i = 0, k; (k = numericKeys[i]); ++i) {
        struct[k] = +struct[k] || 0;
      }

      // allow undefined days and months
      struct[2] = (+struct[2] || 1) - 1;
      struct[3] = +struct[3] || 1;

      if (struct[8] !== 'Z' && struct[9] !== undefined) {
        minutesOffset = struct[10] * 60 + struct[11];

        if (struct[9] === '+') {
          minutesOffset = 0 - minutesOffset;
        }
      }

      timestamp = Date.UTC(struct[1], struct[2], struct[3], struct[4], struct[5] + minutesOffset, struct[6], struct[7]);
    }
    return timestamp;
  }

  // Input example: "2022-03-27 11:17:02"
  // Output example: 1648379822 - seconds from 0 in the past
  getTimestamp(utcTime) {
    let ts = this.parseDate(utcTime.replace(' ', 'T') + "Z")/1000
    return ts
  }

  // Output example: 2023 (assuming it's 2024 now)
  getLastYear() {
    return (new Date().getFullYear() - 1)
  }

}

export default new TimeUtils();