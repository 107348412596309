import React from 'react';
import {Link} from "react-router-dom";

// background
import background from '../assets/images/background_1.jpg';
import world_map from '../assets/svgs/World_map_blue_dots.svg';
import green_alarm from '../assets/svgs/Emblem-important-green.svg';
import {Auth} from "aws-amplify";
import ok from "../assets/svgs/Dialog-accept.svg";

async function isSignedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

class SectionOne extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      signed: null
    };
  }

  componentDidMount() {
    isSignedIn().then( (signed) => {
      this.setState({signed: signed});
    })
  }


  render() {
    return (
      <div>
        {/*<div className="inset-0 bg-black opacity-25 absolute" />*/}
        <div className="mx-auto px-0 md:px-12 relative z-10 flex items-center md:pt-4 md:pt-8 pb-8">
          <div className="flex flex-col items-start4 relative z-10">
            <h1 className="font-bold text-xl md:text-4xl text-black dark:text-white leading-tight mt-4">
              <img src={green_alarm} alt="logo" className="w-12 md:w-16 inline-block"/>&nbsp;Uptime and certificate
              monitoring service
            </h1>
            <h4 className="font-bold text-lg md:text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-2 ml-8">
              for home pages and small projects
            </h4>

            <h1 className="font-bold text-xl md:text-4xl text-black dark:text-white leading-tight mt-8">
              Only <span className="text-green-700 dark:text-green-300">0.83$</span> per month<img src={green_alarm} alt="logo" className="w-12 md:w-16 inline-block"/>
            </h1>

            { this.state.signed === true ?
              <div className="font-bold text-lg text-gray-600 dark:text-gray-400 mb-10 mt-4">
                Welcome!<br/>
                Explore uptime statistics at the <Link className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 pl-1 underline" to="/dashboard/daily/today">Dashboard</Link>,
                update your <Link className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 pl-1 underline" to="/settings">Settings</Link>
                &nbsp;or look at the <a className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 pl-1 underline" href="/docs">Documentation</a>.
              </div>
              : <div className="flex">
                  <Link to="/signin"
                        className="block bg-blue-600 hover:bg-blue-600 py-2 px-3 rounded-lg text-md text-white font-bold mb-10">
                    Sign up now!
                  </Link>
                </div>
            }

            <h2 className="text-xl font-semibold mb-2 text-black dark:text-white">Key Features:</h2>
            <ul className="text-gray-700 dark:text-gray-300 ml-8 mt-4">
              <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> HTTP and HTTPS monitoring</li>
              <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> SSL certificates expiration monitoring</li>
              <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Domain expiration monitoring</li>
              <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Verification from multiple locations</li>
              <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Email notifications</li>
              <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> PagerDuty integration</li>
            </ul>
          </div>
        </div>
        <div className="mx-auto px-0 md:px-6 relative z-10 flex">
          <div className="font-bold text-lg md:text-xl text-gray-600 dark:text-gray-400 leading-tight mt-4">
            🌐 Ensure availability with distributed validation across America, Europe, and Asia.
            <div className="text-gray-700 dark:text-gray-300 text-lg font-normal">
              Validate that your site is reachable from Portland, Frankfurt and Singapore.
            </div>
          </div>
        </div>
        <div className="mx-auto px-6 relative z-10 flex">
          <div className="flex flex-col items-start4 relative z-10">
            <img src={world_map} alt="map" className="inline-block"/>
          </div>
        </div>
        <div className="mx-auto px-0 md:px-6 relative z-10 flex">
          <div className="font-bold text-lg text-gray-600 dark:text-gray-400 leading-tight mt-4">
            📧 Need support or have questions?
            <a
              className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 pl-1 underline"
              href="docs/contacts/">Contact us!</a>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionOne;
