import {usePopperTooltip} from "react-popper-tooltip";
import {useState} from "react";
import SiteCheck from "./SiteCheck";


const Tooltip = ({children, icon}) => {

  const [isVisible, setIsVisible] = useState(false)

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'bottom-start',
    closeOnOutsideClick: true,
    visible: isVisible,
    onVisibleChange: setIsVisible,
    offset: [320, 0],
    interactive: true
  })
  return (
    <>
      <div ref={setTriggerRef}>
        {icon}
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          {children}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </>
  )
}

export default Tooltip;
