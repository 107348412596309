import React from 'react';
import Service from '../api/Service';
import {Link, useParams} from 'react-router-dom';
import SiteCheck from './SiteCheck';
import {Auth} from "aws-amplify";
import TimeUtils from '../utils/TimeUtils';
import Tooltip from "./Tooltip";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip as ChartJSTooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  ChartJSTooltip
);

async function isSignedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

class DailyResultsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      context: props.context,
      date: props.date,
      loaded: false,
      data: null,
      signed: null,
      checks_by_hour_and_site: {},
    };

    if(props.context) {
      props.context.setContext('DailyResultsComponent', this)
    }
  }

  isHashEmpty(obj) {
    for (var o in obj)
      if (o) return false;
    return true;
  }

  componentDidMount() {
    let date = this.state.date;
    if(date === 'today') {
      let d = new Date();
      date =
        d.getUTCFullYear() +
        '-' +
        ('00' + (d.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('00' + d.getUTCDate()).slice(-2)
    }

    isSignedIn().then( (signed) => {
      if (signed) {
        Auth.currentSession().then(session => {
            Service.getDailyResults(date, session.getIdToken().getJwtToken()).then((response) => {
              if (this.isHashEmpty(response.data)) {
                this.setState({
                  loaded: true,
                  signed: true
                });
              } else {
                let region = Object.keys(response.data)[0];
                let results = response.data[region];
                let sites = Object.keys(results)
                let site = sites[0]
                if (this.state.context && this.state.context.getContext('SelectedSite')) {
                  if (sites.includes(this.state.context.getContext('SelectedSite'))) {
                    site = this.state.context.getContext('SelectedSite')
                  }
                }
                let schema = site.split(':')[0]
                let host_and_port = site.split('/')[2]
                let host = host_and_port.split(':')[0]
                let port = host_and_port.split(':')[1]

                // console.log("set init " + site)

                this.setState({
                  loaded: true,
                  signed: true,
                  data: response.data,
                  site: site,
                  schema: schema,
                  host: host,
                  port: port,
                  sites: sites,
                  checks_by_hour_and_site: {}
                });
              }
            });
          }
        )
      } else {
        Service.getDailyResults(date).then((response) => {
          if (this.isHashEmpty(response.data)) {
            this.setState({
              loaded: true,
              signed: false
            });
          } else {
            let region = Object.keys(response.data)[0];
            let results = response.data[region];
            let sites = Object.keys(results)
            let site = sites[0]
            if(this.state.context && this.state.context.getContext('SelectedSite')) {
              if(sites.includes(this.state.context.getContext('SelectedSite'))) {
                site = this.state.context.getContext('SelectedSite')
              }
            }
            let schema = site.split(':')[0]
            let host_and_port = site.split('/')[2]
            let host = host_and_port.split(':')[0]
            let port = host_and_port.split(':')[1]

            // console.log("set init " + site)
            this.setState({loaded: true, signed: false, data: response.data, site: site, schema: schema, host: host, port: port, sites: sites, checks_by_hour_and_site: {}});
          }
        });
      }
    })
  }

  getDate() {
    let date = new Date();
    if (this.state.date && this.state.date !== 'today') {
      date = new Date(this.state.date + 'T00:00:00Z');
    }
    return date
  }

  getChecksByHour(hour, site = null) {
    //console.log(hour);

    if (site === null) {
      site = this.state.site
    }

    if (this.state.checks_by_hour_and_site !== null) {
      if (site in this.state.checks_by_hour_and_site) {
        if (hour in this.state.checks_by_hour_and_site[site]) {
          return this.state.checks_by_hour_and_site[site][hour];
        }
      }
    }

    let date = this.state.date
    let ts = 0

    let today_date = new Date();
    let now_utc = Date.UTC(
      today_date.getUTCFullYear(),
      today_date.getUTCMonth(),
      today_date.getUTCDate(),
      today_date.getUTCHours(),
      today_date.getUTCMinutes(),
      today_date.getUTCSeconds()
    );
    let now_ts = now_utc / 1000;

    if (date === 'today') {
      let today_utc = Date.UTC(
        today_date.getUTCFullYear(),
        today_date.getUTCMonth(),
        today_date.getUTCDate(),
        0,
        0,
        0
      );

      ts = today_utc / 1000;
    } else {
      let date_utc = Date.UTC(
        parseInt(date.split("-")[0]),
        parseInt(date.split("-")[1])-1,
        parseInt(date.split("-")[2]),
        0,
        0,
        0
      );

      ts = date_utc / 1000;
    }

    // console.log("utc_now_ts " + now_ts);
    // console.log("utc_ts " + ts);

    let enchanced_hourly_checks = [];

    let regions = Object.keys(this.state.data);
    regions.forEach((region) => {
      let sites = this.state.data[region];
      let results = sites[site];

      // console.log("rl " + results.length);

      let begin_ts = ts + 60 * 60 * hour;
      let end_ts = ts + 60 * 60 * (hour + 1);

      // console.log("begin_ts " + begin_ts);
      // console.log("end_ts " + end_ts);

      let hourly_checks = results.filter(
        (result) => TimeUtils.getTimestamp(result.time) >= begin_ts && TimeUtils.getTimestamp(result.time) < end_ts
      );

      // console.log("fl " + hourly_checks.length);

      let interval = 120;
      let minute_start = begin_ts;
      let minute_end = minute_start + interval;
      let check_number = 0;
      let check = {};
      for (let i = 0; i < 60*60/interval; i++) {
        let found = false;
        while (
          check_number < hourly_checks.length &&
          TimeUtils.getTimestamp(hourly_checks[check_number].time) < minute_start
        ) {
          let errors = hourly_checks[check_number].errors;
          // hourly_checks[check_number].errors.forEach((error) => {
          //   errors[error] = 1;
          // });
          check = {
            errors: errors,
            label:
              region +
              ' ' +
              TimeUtils.getTimeLabel(TimeUtils.getTimestamp(hourly_checks[check_number].time))
          };
          this.addCheck(enchanced_hourly_checks, i, check);

          // check.time = hourly_checks[check_number].time
          // hourly_checks[check_number].errors.forEach(element => {
          //     if (check.errors[element]) {
          //         check.errors[element] = check.errors[element] + 1
          //     } else {
          //         check.errors[element] = 1
          //     }
          // });
          check_number += 1;
          found = true;
        }
        // if(found) {
        //     enchanced_hourly_checks[i] = (check)
        // }

        found = false;
        // check = {
        //     errors: {}
        // }
        while (
          check_number < hourly_checks.length &&
          TimeUtils.getTimestamp(hourly_checks[check_number].time) >= minute_start &&
          TimeUtils.getTimestamp(hourly_checks[check_number].time) < minute_end
        ) {
          let errors = hourly_checks[check_number].errors;
          // hourly_checks[check_number].errors.forEach((error) => {
          //   errors[error] = 1;
          // });
          check = {
            errors: errors,
            label:
              region +
              ' ' +
              TimeUtils.getTimeLabel(TimeUtils.getTimestamp(hourly_checks[check_number].time))
          };
          this.addCheck(enchanced_hourly_checks, i, check);

          // check.time = hourly_checks[check_number].time
          // hourly_checks[check_number].errors.forEach(element => {
          //     if (check.errors[element]) {
          //         check.errors[element] = check.errors[element] + 1
          //     } else {
          //         check.errors[element] = 1
          //     }
          // });
          check_number += 1;
          found = true;
        }
        // if(found) {
        //     enchanced_hourly_checks[i] = (check)
        // }

        if (!found && minute_end < now_ts) {
          if (now_ts - minute_end < 15 * 60) {
            let pending_check = {
              errors: { pending: 1 },
              label: region + ' ' + TimeUtils.getTimeLabel(minute_start)
            };
            this.addCheck(enchanced_hourly_checks, i, pending_check);
          } else {
            let missed_check = {
              errors: { missed: 1 },
              label: region + ' ' + TimeUtils.getTimeLabel(minute_start)
            };
            this.addCheck(enchanced_hourly_checks, i, missed_check);
          }
        }
        minute_start = minute_end;
        minute_end = minute_start + interval;
      }
    });

    //console.log("rl " + enchanced_hourly_checks.length);



    // enchanced_hourly_checks.map((checks) => (
    //   checks.map((check) => (
    //       console.log("enchanced_hourly_checks " + Object.keys(check.errors))
    //     ))
    //   ))

    let checks_by_hour_and_site = this.state.checks_by_hour_and_site
    if (!(site in checks_by_hour_and_site)) {
      checks_by_hour_and_site[site] = {}
    }
    checks_by_hour_and_site[site][hour] = enchanced_hourly_checks
    this.setState({checks_by_hour_and_site: checks_by_hour_and_site})

    return enchanced_hourly_checks;
  }

  addCheck(checks, position, check) {
    if (checks[position]) {
      checks[position].push(check);
    } else {
      checks[position] = [check];
    }
  }

  loadSite(site) {
    TimeUtils.hours.map((hour) => (this.getChecksByHour(hour, site)))
    this.setState({loaded: true});
  }

  handleSiteChange(e) {
    let site = e.target.value
    if(this.state.context) {
      this.state.context.setContext('SelectedSite', site)
    }

    let schema = site.split(':')[0]
    let host_and_port = site.split('/')[2]
    let host = host_and_port.split(':')[0]
    let port = host_and_port.split(':')[1]

    this.setState({loaded: false, site: site, schema: schema, host: host, port: port});

    setTimeout(() => (this.loadSite(site)), 0)
  }

  getChartOptions() {

    let theme = "light";
    if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
      theme = "dark";
    }

    let options = {
      responsive: true,
      scales: {
        y: {
          grid:{
            color: theme === 'dark' ? "rgb(26,26,26)" : "rgb(219,219,219)",
          },
          ticks: {
            color: "rgb(128,128,128)",
          },
          min: 0
        },
        x: {
          grid:{
            color: theme === 'dark' ? "rgb(26,26,26)" : "rgb(219,219,219)",
          },
          ticks: {
            color: "rgb(128,128,128)",
          }
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyleWidth: 17
          }
        },
        title: {
          display: true,
          text: '',
        },
        tooltip: {
          backgroundColor: "rgb(200,200,200)",
          borderColor: "rgb(229, 231, 235)",
          borderWidth: 1,
          bodyColor: "rgb(0,0,0)",
          titleColor: "rgb(0,0,0)",
        }
      },
    };
    return options
  }

  getChartData() {
    let labels = TimeUtils.hours

    let error_types = [
      'bad-url',
      'unknown-scheme',
      'not-ok',
      'failed-ssl',
      'failed-cert',
      'future-cert',
      'past-cert',
      'expiring-cert',
      'expired-domain',
      'expiring-domain',
      'missed',
      'pending',
      'ok']

    let colors = [
      'rgb(218,70,241)',
      'rgb(166,40,185)',
      'rgb(255,6,6)',
      'rgb(255, 99, 132)',
      'rgb(197,44,154)',
      'rgb(236,155,48)',
      'rgb(196,22,58)',
      'rgb(225,117,59)',
      'rgb(255,82,58)',
      'rgb(225,217,59)',
      'rgb(143,143,143)',
      'rgb(62,107,220)',
      'rgb(109,206,28)']

    let errors_by_hour = TimeUtils.hours.map((hour) => (
      this.getChecksByHour(hour).reduce((a,c) => a.concat(c), []).map((checks) => (checks['errors']))
    ))

    let datasets = []
    for (let index = 0; index < error_types.length; ++index) {
      const error_type = error_types[index];

      let d = errors_by_hour.map((errors) => errors.reduce((a,c) => a + (c[error_type] ? c[error_type] : 0 ),0))

      if (!d.every(item => item === 0)) {
        if (this.props.params.date === 'today') {
          d = d.slice(0, new Date().getUTCHours() + 1)
        }

        datasets.push({
          label: error_type,
          data: d,
          borderColor: colors[index],
          backgroundColor: colors[index],
        })
      }
    }

    let data = {
      labels,
      datasets: datasets
    };

    return data
  }

  render() {
    if (this.state.loaded) {
      if (this.state.data == null) {
        return (
          <div>
            <h1 className="font-semibold text-gray-900 dark:text-gray-100 pb-8">
              <h4 className="font-bold text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4 pt-2 md:pt-16 pb-8">
                Daily&nbsp;health&nbsp;dashboard
              </h4>
              {TimeUtils.getDateLabel(this.getDate())}
            </h1>
            <div className="bg-blue-100 rounded-lg py-5 px-6 text-base text-blue-700 dark:text-blue-300" role="alert">
              {(this.state.date === 'today') ?
                <div className="bg-blue-100 rounded-lg py-5 px-6 text-base text-blue-700 dark:text-blue-300" role="alert">
                  No data is collected yet.
                  Please double check that sites to monitor are added in
                  <Link
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 pl-1 underline"
                    to="/settings">Settings</Link>.
                </div>
                :
                <div className="bg-blue-100 rounded-lg py-5 px-6 text-base text-blue-700 dark:text-blue-300" role="alert">
                  No data is collected for the requested period.
                </div>
              }
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <h1 className="font-semibold text-gray-900 dark:text-gray-100 pb-5">
              <h4 className="font-bold text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4 pt-2 md:pt-16 pb-8">
                Daily&nbsp;health&nbsp;dashboard {this.state.signed === false ? <span className="text-red-500 dark:text-red-500 align-super text-lg">[demo]</span> : ""}
              </h4>
              {TimeUtils.getDateLabel(this.getDate())} &nbsp;&nbsp;
              <div className="inline-block md:w-80">
                <div className="mb-3 w-[256px] md:w-96 arrow">
                  <select value={this.state.site}
                          onChange={(e) => this.handleSiteChange(e)}
                          className="cursor-pointer form-select appearance-none block w-full px-3 pr-8 py-1.5 text-base font-normal text-gray-700 dark:text-gray-300 bg-white dark:bg-black
                                   bg-clip-padding bg-no-repeat border border-solid border-gray-300 dark:border-gray-400 rounded transition ease-in-out m-0
                                   focus:text-gray-700 dark:focus:text-gray-300 focus:bg-white dark:focus:bg-black focus:border-blue-600 dark:focus:border-blue-400 focus:outline-none"
                          aria-label="Select domain">
                    {this.state.sites.map((site) => <option value={site}>{site}</option>)}
                  </select>
                </div>
              </div>
            </h1>
            {this.state.signed === false ?
              <div className="bg-blue-100 dark:bg-blue-900 rounded-lg py-5 px-6 mb-2 text-base text-blue-700 dark:text-blue-300 mb-3" role="alert">
                You are not signed in.<br/>
                This is dashboard for default set of sites.
              </div> : ""
            }
            <div className="rounded-xl border border-gray-100 dark:border-gray-600 bg-white dark:bg-black px-5 pt-0 pb-0 flex overflow-x-auto">
              <div className="flex-shrink-0 w-full">
                {TimeUtils.hours.map((hour) => (
                  <div className={"flex font-sans bg-white dark:bg-black text-black dark:text-white p-4 px-6 pt-2 pb-0 gap-2 text-sm leading-6" + (hour === 23 ? " " : " border-b ") + "border-neutral-200 dark:border-neutral-800"}>
                    <div className="inline-block px-0 pb-1">
                      <Tooltip icon={TimeUtils.getShortHourLabel(hour)}>
                      <span
                        className="absolute right-0 rounded-md shadow-md text-black bg-white dark:bg-black dark:text-white border border-gray-200 dark:border-gray-500 text-xs font-bold transition-all duration-100 p-2 text-left min-w-max">
                        {TimeUtils.getHourLabel(hour)}
                      </span>
                      </Tooltip>
                    </div>
                    <div className="inline-block px-6">
                      {this.getChecksByHour(hour).map((checks) => (
                        <SiteCheck data={checks}/>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="rounded-xl border border-gray-100 dark:border-gray-600 bg-white dark:bg-black px-5 my-3 pt-5 pb-5 flex overflow-x-auto">
              <div className="w-[850px] h-[425px] min-w-[850px] min-h-[425px]">
                <Line options={this.getChartOptions()} data={this.getChartData()}
                      className="w-[850px] h-[425px] min-w-[850px] min-h-[425px]"/>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <h1 className="font-semibold text-gray-900 dark:text-gray-100 pb-8">
            <h4 className="font-bold text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4 pt-2 md:pt-16 pb-8">
              Daily&nbsp;health&nbsp;dashboard
            </h4>
            {TimeUtils.getDateLabel(this.getDate())} &nbsp;&nbsp;
            { (this.state.site && this.state.sites) ?
              <div className="inline-block md:w-80">
              <div className="mb-3 w-[256px] md:w-96 arrow">
              <select value={this.state.site}
              onChange={(e) => this.handleSiteChange(e)}
              className="cursor-pointer form-select appearance-none block w-full px-3 pr-8 py-1.5 text-base font-normal text-gray-700 dark:text-gray-300 bg-white
                                   bg-clip-padding bg-no-repeat border border-solid border-gray-300 dark:border-gray-400 rounded transition ease-in-out m-0
                                   focus:text-gray-700 dark:focus:text-gray-300 focus:bg-white dark:bg-black dark:focus:bg-black focus:border-blue-600 dark:focus:border-blue-400 focus:outline-none"
              aria-label="Select domain">
            {this.state.sites.map((site) => <option value={site}>{site}</option>)}
              </select>
              </div>
              </div> : ""
            }
          </h1>
          <div className="pt-2 rounded-xl border border-gray-100 dark:border-gray-600 bg-white dark:bg-black p-0 px-5 pt-0 pb-0">
            <div className="m-4 spinner-border animate-spin border-blue-500 inline-block w-8 h-8 border-4 rounded-full border-t-transparent" role="status"/>
          </div>
        </div>
      );
    }
  }
}

export default (props) => (
  <DailyResultsComponent {...props} params={useParams()} />
);

// export default DailyResultsComponent
