import React from 'react';

// components
import Header from '../components/Header';
import SectionOne from '../components/SectionOne';

const LandingPageView = () => {
  return (
    <div className="container mx-auto space-y-4 p-6 md:px-8 py-6 max-w-[1000px] min-w-[320px]">
      <SectionOne />
      {/*// TODO: add features, pricing and about sections*/}
    </div>
  );
};

export default LandingPageView;
