import React, {useEffect} from "react";

const NotificationSelect = ({notifications, selected, onChange, disabled}) => {

  useEffect(() => {
    const use = async () => {
      const { initTE, Select } = await import('tw-elements');
      initTE({ Select }, { allowReinits: true });
    };
    use();
  }, []);

  return (
    <div className="w-full bg-white dark:bg-black rounded">
      {(notifications.filter((n) => (n.name != null && n.name !== "")).length === 0) ? "Please first create notification role." :
        <select data-te-select-init
                data-te-select-placeholder="Select who to notify"
                multiple
                disabled={disabled}
                className="invisible w-full"
                onChange={(e) => {
                  onChange(e)}}
        >
          {notifications.map((notification) =>
            <option value={notification.name} selected={selected.includes(notification)}>{notification.name}</option>
          )}
        </select>
      }
    </div>
  );
};

export default NotificationSelect;