import React from 'react';

// components
import Header from '../components/Header';
import SectionOne from '../components/SectionOne';
import {Link} from "react-router-dom";
import {Auth} from "aws-amplify";
import ok from '../assets/svgs/Dialog-accept.svg';


async function isSignedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

class PricingView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      signed: null
    };
  }

  componentDidMount() {
    isSignedIn().then( (signed) => {
      this.setState({signed: signed});
    })
  }


  render() {
    return (
      <div className="container mx-auto space-y-4 p-6 md:px-8 py-6 max-w-[1000px] min-w-[320px]">

        <div className="font-bold text-2xl lg:text-2xl text-blue-500 leading-tight mt-4">
          Pricing:
        </div>

        <div className="flex flex-wrap justify-center">
          <div className="rounded-xl border border-grey-100 dark:border-grey-600 bg-white dark:bg-black px-5 my-3 pt-5 pb-5 mr-5 flex inline-block w-[440px]">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-5 dark:text-white">Free 1 month trial</div>
              <p className="text-gray-700 dark:text-gray-300 mb-5">Try for free.</p>
              <ul className="text-gray-700 dark:text-gray-300">
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Credits for 1 month of once-a-minute checks for one domain</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> HTTP and HTTPS monitoring</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> SSL certificates expiration monitoring</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Domain expiration monitoring</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Verification from multiple locations</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Email notifications</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> PagerDuty integration</li>
              </ul>
              <div className="flex">
                { this.state.signed === false ?
                  <Link to="/signin"
                        className="block bg-blue-600 hover:bg-blue-600 py-2 px-3 rounded-lg text-md text-white font-bold mt-5">
                    Sign up now!
                  </Link>
                  : ""}
              </div>
            </div>
          </div>

          <div className="rounded-xl border border-grey-100 dark:border-grey-600 bg-white dark:bg-black px-5 my-3 pt-5 pb-5 mr-5 flex inline-block w-[440px]">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-5 dark:text-white"><span className="text-green-700 dark:text-green-300">0.83$</span>/month standard plan</div>
              <div className="font-bold text-l mb-5 dark:text-white">10$ are billed annually.</div>
              <ul className="text-gray-700 dark:text-gray-300">
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Credits for 1 year of once-a-minute checks for one domain</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> HTTP and HTTPS monitoring</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> SSL certificates expiration monitoring</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Domain expiration monitoring</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Verification from multiple locations</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> Email notifications</li>
                <li className="mb-2"> <img src={ok} className="w-6 inline-block"/> PagerDuty integration</li>
              </ul>
              <div className="flex">
                { this.state.signed === false ?
                  <Link to="/signin"
                        className="block bg-blue-600 hover:bg-blue-600 py-2 px-3 rounded-lg text-md text-white font-bold mt-5">
                    Sign up now!
                  </Link>
                  :
                  <Link to="/settings"
                        className="block bg-blue-600 hover:bg-blue-600 py-2 px-3 rounded-lg text-md text-white font-bold mt-5">
                    Buy!
                  </Link>
                }
              </div>
            </div>
          </div>
        </div>


      </div>)
  }
}

export default PricingView;
