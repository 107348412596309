import React from 'react';
import {Link, useParams} from 'react-router-dom';
import AuthenticationLink from "./AuthenticationLink";
import green_alarm from '../assets/svgs/Emblem-important-green.svg';

import {useEffect} from "react";

const Header = (props) => {

  useEffect(() => {
    const use = async () => {
      const { initTE, Collapse, Ripple } = await import('tw-elements');
      initTE({ Collapse, Ripple }, { allowReinits: true });
    };
    use();
  }, []);

  return (
    <div>
      <div
        className="bg-white dark:bg-black shadow relative w-full items-center justify-between py-2.5 text-neutral-500 dark:text-neutral-500 shadow-md hover:text-neutral-700 dark:hover:text-neutral-700 focus:text-neutral-700 dark:hover:text-neutral-700 justify-start">
        <div className="w-full items-center justify-between px-6">
          <button
            className="inline-block border-0 bg-transparent px-2.5 py-2 text-neutral-500 dark:text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContentY"
            aria-controls="navbarSupportedContentY"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
              className="w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor"
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
              </path>
            </svg>
          </button>

          <div className="flex inline-block">
          <div
            className="hidden invisible flex-grow items-center lg:flex basis-auto items-center max-w-7xl mx-auto px-2.5 justify-between lg:visible inline-block">
            <Link className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 flex-shrink-0" to="/">
              <img
                src={green_alarm}
                alt="logo"
                className="w-6 inline-block"
              />&nbsp;
              Is Site Ok?
            </Link>
            <div className="inline">
              <div className="inline ml-3 flex items-baseline space-x-4">
                <div
                  className="inline mr-auto flex pl-0 lg:flex-row md:flex-row sm:flex-row list-style-none"
                  >
                  <span className="px-2 py-2">
                    <Link
                      className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                      to="/dashboard/daily/today"
                    >
                      Dashboard
                    </Link>
                  </span>
                  <span className="px-2 py-2">
                    <a
                      className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                      href="/docs"
                    >
                      Docs
                    </a>
                  </span>
                  <span className="px-2 py-2">
                    <Link
                      className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                      to="/settings"
                    >
                      Settings
                    </Link>
                  </span>
                  <span className="px-2 py-2">
                    <Link
                      className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                      to="/pricing"
                    >
                      Pricing
                    </Link>
                  </span>
                  <span className="px-2">
                    <AuthenticationLink id="header1" context={props.context} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          </div>

        </div>


      <nav
        className="lg:hidden"
        // relative flex w-full items-center justify-between bg-white py-2 text-neutral-600 shadow-lg hover:text-neutral-700 focus:text-neutral-700 dark:bg-neutral-600 dark:text-neutral-200 md:flex-wrap md:justify-start
        data-te-navbar-ref>
        <div className="flex w-full flex-wrap items-center justify-between px-3">
          <div
            className="!visible hidden grow basis-[100%] items-center lg:!flex lg:basis-auto"
            id="navbarSupportedContentY"
            data-te-collapse-item>


            <ul className="relative m-0 list-none px-[0.2rem]" data-te-sidenav-menu-ref>
              <li className="relative">
                <Link
                  className="flex-shrink-0 text-gray-400 dark:text-gray-600 hover:text-gray-800 dark:hover:text-gray-200 rounded-md text-sm font-medium"
                  to="/">
                  <span
                    className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-600 outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-100 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-400 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10">
                                <img
                                  src={green_alarm}
                                  alt="logo"
                                  className="w-6 inline-block"
                                />&nbsp;
                    Is Site Ok?
                  </span>
                </Link>
              </li>
              <li className="relative">
                <Link
                  className="flex-shrink-0 text-gray-400 dark:text-gray-600 hover:text-gray-800 dark:hover:text-gray-200 rounded-md text-sm font-medium"
                  to="/dashboard/daily/today">
                  <span
                    className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-600 outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-100 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-400 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10">
                    Dashboard
                  </span>
                </Link>
              </li>
              <li className="relative">
                <a
                  className="text-gray-400 dark:text-gray-600 hover:text-gray-800 dark:hover:text-gray-200 rounded-md text-sm font-medium"
                  href="/docs"
                >
                <span
                  className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-600 outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-100 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-400 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10">
                      Docs
                </span>
                </a>
              </li>
              <li className="relative">
                <Link
                  className="text-gray-400 dark:text-gray-600 hover:text-gray-800 dark:hover:text-gray-200 rounded-md text-sm font-medium"
                  to="/settings"
                >
                <span
                  className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-600 outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-100 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-400 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10">
                      Settings
                </span>
                </Link>
              </li>
              <li className="relative">
                <Link
                  className="text-gray-400 dark:text-gray-600 hover:text-gray-800 dark:hover:text-gray-200 rounded-md text-sm font-medium"
                  to="/pricing"
                >
                <span
                  className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-600 outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-100 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-400 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10">
                      Pricing
                </span>
                </Link>
              </li>
              <li className="relative">
                <span
                  className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-600 outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-100 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-400 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10">
                  <AuthenticationLink id="header2" context={props.context}/>
                </span>
              </li>
            </ul>


          </div>
        </div>
      </nav>

      </div>
    </div>
  );
};

export default Header;
