import axios from 'axios';

//const USERS_REST_API_URL = 'http://localhost:8080/daily/';
const DAILY_REST_API_URL =
  'https://issiteok.com:443/api/checks/daily/';
const YEARLY_REST_API_URL =
  'https://issiteok.com:443/api/checks/yearly/';

const SETTINGS_REST_API_URL =
  'https://issiteok.com:443/api/settings/';

const MAIL_VERIFICATION_REST_API_URL =
  'https://issiteok.com:443/api/mail/verification/';

const PAYMENTS_REST_API_URL =
  'https://issiteok.com:443/api/payments/';

class Service {
  getDailyResults(date, token = null) {
    if (token) {
      return axios.get(DAILY_REST_API_URL + date, {headers: {'Authorization': 'Bearer ' + token}});
    } else {
      return axios.get(DAILY_REST_API_URL + date);
    }
  }

  getYearlyResults(year, token = null) {
    if (token) {
      return axios.get(YEARLY_REST_API_URL + year, {headers: {'Authorization': 'Bearer ' + token}});
    } else {
      return axios.get(YEARLY_REST_API_URL + year);
    }
  }

  getSettings(token = null) {
    if (token) {
      return axios.get(SETTINGS_REST_API_URL, {headers: {'Authorization': 'Bearer ' + token}});
    } else {
      return axios.get(SETTINGS_REST_API_URL);
    }
  }

  setSettings(token, settings) {
    return axios.post(SETTINGS_REST_API_URL, settings, {headers: {'Authorization': 'Bearer ' + token, 'Content-Type' : 'text/plain' }});
  }

  deleteAccount(token) {
    return axios.delete(SETTINGS_REST_API_URL, {headers: {'Authorization': 'Bearer ' + token}});
  }

  isMailVerified(token, email) {
    return axios.get(MAIL_VERIFICATION_REST_API_URL + email, {headers: {'Authorization': 'Bearer ' + token, 'Accept': 'text/plain' }});
  }

  verifyMail(token, email) {
    return axios.post(MAIL_VERIFICATION_REST_API_URL, email, {headers: {'Authorization': 'Bearer ' + token, 'Content-Type' : 'text/plain'}});
  }

  processPayment(token, transaction) {
    return axios.post(PAYMENTS_REST_API_URL, transaction, {headers: {'Authorization': 'Bearer ' + token, 'Content-Type' : 'text/plain' }});
  }

  // test
  getA(token) {
      return axios.get(DAILY_REST_API_URL + "a", {headers: {'Authorization': 'Bearer ' + token}});
  }

}

export default new Service();
