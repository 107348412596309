import { Route, Routes } from 'react-router-dom';

import './App.css';

// views
import DashboardView from './views/DashboardView';
import SettingsView from "./views/SettingsView";
import LandingPageView from './views/LandingPageView';
import DailyResultsComponent from "./components/DailyResultsComponent";
import YearlyResultsComponent from "./components/YearlyResultsComponent";
import SignInView from "./views/SignInView";
import Header from "./components/Header";
import React, {useState} from "react";
import PricingView from "./views/PricingView";

function App() {
  let CONTEXT = {
    setContext: (k, v) => {CONTEXT[k] = v},
    getContext: (k) => {return CONTEXT[k]}
  }

  const [context, setContext] = useState({});

  return (
    <div className="bg-gray-50 dark:bg-docs-dark w-full">
      <Header context={CONTEXT} />
      <Routes>
        <Route exact path="/" element={<LandingPageView context={CONTEXT} />} />
        <Route path="/dashboard/:type/:date" element={<DashboardView context={CONTEXT} />} />
        <Route path="/settings" element={<SettingsView context={CONTEXT} />} />
        <Route path="/pricing" element={<PricingView context={CONTEXT} />} />
        <Route path="/signin" element={<SignInView context={CONTEXT} />} />
      </Routes>
    </div>
  );
}

export default App;
