import '@aws-amplify/ui-react/styles.css';
import '../index.css';

import React from "react";

import {Amplify, Auth, I18n} from "aws-amplify";
import { translations } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import {
  ThemeProvider,
  useTheme,
  defaultDarkModeOverride
} from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";

import Header from "../components/Header";
import AuthenticationLink from "../components/AuthenticationLink";

import background from "../assets/images/background_1.jpg";
import {Link} from "react-router-dom";



Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_K6cBfmP01',
    userPoolWebClientId: '7vg5o36174qba5fval1gc1hhsv'
  }
});

I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabularies({en: {
  'Create Account': 'Sign up', // Tab header
  'Sign In': 'Sign in', // Tab header
  'Forgot your password?': 'Reset password',
}});

async function isSignedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

function withUseTheme(Component) {
  return function WrappedComponent(props) {
    const theme = useTheme();
    return <Component {...props} theme={theme} />;
  }
}

function withNavigate(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }
}


class SignInView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      context: props.context,
      email: null,
      email_verified: null
    };

    if(props.context) {
      props.context.setContext('SignInView', this)
    }
  }

  componentDidMount() {
    this.getUserAttributes()
  }

  getUserAttributes() {
    Auth.currentAuthenticatedUser().then((user) => {
      if ('attributes' in user) {
        if ('email' in user.attributes) {
          let email = user.attributes.email
          let email_verified = ('email_verified' in user.attributes) ? user.attributes.email_verified : false
          if (email !== this.state.email || email_verified !== this.state.email_verified) {
            this.setState({email: email, email_verified: email_verified})
          }
        }
      }
    }).catch(() => {})
  }

  onSignIn(user) {
    isSignedIn().then( (signed) => {
      if(this.state.context && this.state.context['AuthenticationLinks']) {
        for (const i in this.state.context['AuthenticationLinks']) {
          this.state.context['AuthenticationLinks'][i].setState({ signed: signed, loaded: true})
        }
      }

      if (signed) {
        let was_signed_before = (this.state.email !== null)

        this.getUserAttributes()

        if(! was_signed_before) {
          this.props.navigate('/dashboard/daily/today')
        }
      } else {
        if (this.state.email !== null) {
          this.setState({email: null, email_verified: null})
        }
      }
    })
  }

  formFields () {

    return {
      signIn: {
        username: {
          label: 'Email:',
          placeholder: 'email',
          isRequired: true,
        },
        password: {
          label: 'Password:',
          placeholder: 'password',
          isRequired: true,
        }
      },
      signUp: {
        username: {
          label: 'Email:',
          placeholder: 'email',
          isRequired: true,
        },
        password: {
          label: 'Password:',
          placeholder: 'password',
          isRequired: true,
        },
        confirm_password: {
          label: 'Confirm password:',
          placeholder: 'password',
          isRequired: true,
        },
      },
      forceNewPassword: {
        password: {
          placeholder: 'password',
          isRequired: true,
        },
      },
      forgotPassword: {
        username: {
          placeholder: 'email',
          isRequired: true,
        },
      },
      confirmResetPassword: {
        confirmation_code: {
          label: 'Confirmation code:',
          placeholder: 'confirmation code',
          isRequired: true,
        },
        confirm_password: {
          placeholder: 'password',
          isRequired: true,
        },
      },
      confirmSignIn: {
        confirmation_code: {
          label: 'Confirmation code:',
          placeholder: 'confirmation code',
          isRequired: true,
        },
      },
    };
  }

  getTheme() {
    var theme = {
      name: 'Auth Example Theme',
      // tokens: {
      //   colors: {
      //     background: {
      //       primary: {
      //         value: "#000",
      //       },
      //     },
      //   },
      // },
      overrides: [defaultDarkModeOverride]
    };
    return theme;
  }

  render() {
    return (
      <div>
        <div className="container mx-auto space-y-4 p-4 px-8 py-16 max-w-[600px] min-w-[320px]">
          <div className="flex justify-center items-center">
            <ThemeProvider theme={this.getTheme()} colorMode="system">
              <Authenticator formFields={this.formFields()} hideSignUp={false}>
                {({ signOut, user }) => {
                  this.onSignIn(user)
                  return ("")}}
              </Authenticator>
            </ThemeProvider>
            {this.state.email ?
              <div>
                <h4 className="font-bold text-2xl text-blue-500 leading-tight mt-4 pb-8 md:pb-16">
                  <div>You are signed in with {this.state.email} email.</div>
                  {
                    this.state.email_verified ? "" :
                      <div>Please verify email to finish registration.</div>
                  }
                  <div className="w-40 pt-4 pb-4">
                    <AuthenticationLink id="signout" context={this.state.context} />
                  </div>
                  Continue to:
                  <ul>
                    <li className="px-2 py-2">
                      <Link
                        className="text-gray-500 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-200 px-3 py-2 rounded-md text-md font-medium"
                        to="/settings"
                      >
                        Settings
                      </Link>
                    </li>
                    <li className="px-2 py-2">
                      <Link
                        className="text-gray-500 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-200 px-3 py-2 rounded-md text-md font-medium"
                        to="/dashboard/daily/today"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="px-2 py-2">
                      <a
                        className="text-gray-500 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-200 px-3 py-2 rounded-md text-md font-medium"
                        href="/docs"
                      >
                        Documentation
                      </a>
                    </li>
                  </ul>

                </h4>
              </div>
              : ""}

          </div>
        </div>
      </div>
    )
  }
}

export default withNavigate(withUseTheme(SignInView));
