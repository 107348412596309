const Button = ({ children, onClick, className, name }) => {
  return (
    <button
      type="button"
      name={name}
      className={className + " py-2 bg-blue-600 hover:bg-blue-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md"}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
