import React, { useState } from 'react';

// components
import Button from '../components/Button';
import {Auth} from "aws-amplify";
import Service from "../api/Service";
import {Link} from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import red_alarm from '../assets/svgs/Emblem-important-red.svg';
import { useNavigate } from "react-router-dom";
import {useEffect} from "react";
import NotificationSelect from "../components/NotificationSelect";

async function isSignedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}
class SettingsView extends React.Component {

  constructor(props) {
    super(props);

    this.deleteAccount = this.deleteAccount.bind(this);
    this.closeDeleteAccountModal = this.closeDeleteAccountModal.bind(this);
    this.handleCreditsAlarmChange = this.handleCreditsAlarmChange.bind(this);

    this.state = {
      context: props.context,
      notifications: [],
      sites: [],
      signed: null,
      save_state: 'unchanged',
      credits: null,
      used_credits: null,
      credits_alarm_notifications: [],
      isDeleteAccountModalOpen: false
    };
  }

  isDebug() {
    return document.location.search.includes("debug=true")
  }

  getSaveButtonStyle(save_state) {
    if(save_state === 'failed') {
      return "rounded-lg px-4 bg-red-700 hover:bg-red-800 focus:ring-red-600 focus:ring-offset-red-300"
    }
    if(save_state === 'unchanged') {
      return "rounded-lg px-4 bg-gray-700 hover:bg-gray-800 focus:ring-neutral-600 focus:ring-offset-gray-300"
    }
    if(save_state === 'changed') {
      // default button
      return "rounded-lg px-4"
    }
    if(save_state === 'saved') {
      return "rounded-lg px-4 bg-green-700 hover:bg-green-800 focus:ring-green-600 focus:ring-offset-green-300"
    }
    if(save_state === 'saving') {
      // default button + sin
      return "rounded-lg px-4 animate-spin"
    }
  }

  getSaveButtonText(save_state) {
    if(save_state === 'failed') {
      return "Retry save"
    }
    if(save_state === 'unchanged') {
      return "Save"
    }
    if(save_state === 'changed') {
      // default button
      return "Save"
    }
    if(save_state === 'saved') {
      return "Saved!"
    }
    if(save_state === 'saving') {
      // default button + spin
      return "Saving"
    }
  }

  getAddButtonStyle(add_state) {
    if(add_state === 'max_sites' || add_state === 'unsigned') {
      return "px-2 rounded-full bg-gray-700 hover:bg-gray-800 focus:ring-neutral-600 focus:ring-offset-gray-300"
    }
    if(add_state === 'normal') {
      // default button
      return "px-2 rounded-full"
    }
  }

  getPayButtonStyle(state) {
    if(state === 'unsigned') {
      return "px-2 rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-neutral-600 focus:ring-offset-gray-300"
    }
    if(state === 'normal') {
      // default button
      return "px-2 rounded-lg"
    }
  }

  getAccountDeleteButtonStyle(state) {
    if(state === 'unsigned') {
      return "px-2 rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-neutral-600 focus:ring-offset-gray-300"
    }
    if(state === 'normal') {
      // default button
      return "px-2 rounded-lg bg-red-700 hover:bg-red-800 focus:ring-red-600 focus:ring-offset-red-300"
    }
  }

  getDeleteButtonStyle(delete_state) {
    if(delete_state === 'unsigned') {
      return "px-2 rounded-full bg-gray-700 hover:bg-gray-800 focus:ring-neutral-600 focus:ring-offset-gray-300"
    }
    if(delete_state === 'normal') {
      return "px-2 rounded-full bg-red-700 hover:bg-red-800 focus:ring-red-600 focus:ring-offset-red-300"
    }
  }

  getVerifyEmailButtonStyle(email_state, site, email) {
    if (!this.state.signed) {
      return "rounded-lg px-4 bg-gray-700 hover:bg-gray-800 focus:ring-neutral-600 focus:ring-offset-gray-300"
    }

    if (site === "credits") {
      if (email_state == null) {
        isSignedIn().then((signed) => {
            if (signed) {
              Auth.currentSession().then(session => {
                Service.isMailVerified(session.getIdToken().getJwtToken(), email).then((response) => {
                  if (response && response.data === true) {
                    this.setState({credits_alarm_email_state: 'verified'});
                  } else {
                    this.setState({credits_alarm_email_state: 'unverified'});
                  }
                })
              })
            }
          }
        );
      }
    } else {
      if (email_state === null) {
        isSignedIn().then((signed) => {
            if (signed) {
              Auth.currentSession().then(session => {
                Service.isMailVerified(session.getIdToken().getJwtToken(), email).then((response) => {
                  if (response && response.data === true) {
                    let sites = this.state.sites
                    site.email_state = 'verified'
                    this.setState({sites: sites});
                  } else {
                    let sites = this.state.sites
                    site.email_state = 'unverified'
                    this.setState({sites: sites});
                  }
                })
              })
            }
          }
        );
      }
    }

    if(email_state === 'verified') {
      return "rounded-lg px-4 bg-green-700 hover:bg-green-800 focus:ring-green-600 focus:ring-offset-green-300"
    }
    // default button
    return "rounded-lg px-4"
  }

  getVerifyEmailButtonText(email_state) {
    if(email_state === 'verified') {
      return "Verified"
    }
    return "Verify"
  }

  randomID() {
    let rand = Math.random().toString(16).substr(2, 8);
    return rand
  }

  componentDidMount() {
    isSignedIn().then( (signed) => {
      this.setState({signed: signed})
      if (signed) {
        Auth.currentSession().then(session => {
            Service.getSettings(session.getIdToken().getJwtToken()).then((response) => {
              let data = null
              if(response && ('data' in response)) {
                data = response.data
              }
              this.loadSettingsAndCredits(data)
            });
          }
        )
      } else {
        Service.getSettings().then((response) => {
          let data = null
          if(response && ('data' in response)) {
            data = response.data
          }
          this.loadSettingsAndCredits(data)
        });
      }
    })
  }

  loadSettingsAndCredits(data) {
    let sites = []
    let notifications = []
    let credits_alarm_notifications = []
    let credits = null
    let used_credits = null


    if(data != null && data !== "" && ('settings' in data)) {
      let json = JSON.parse(data.settings)

      if (json['notifications']) {
        for (const i in json['notifications']) {
          let notification = json['notifications'][i]
          let name = notification['name']
          let pagerduty_integration_key = notification['pagerduty_integration_key']
          let email = notification['email']

          notifications.push({
            id: this.randomID(),
            name: name,
            pagerduty_integration_key: pagerduty_integration_key,
            email: email,
            email_state: null
          })
        }
      }

      if (json['sites']) {
        for (const i in json['sites']) {
          let site = json['sites'][i]
          let url = site['url']
          let alarm_notification_names = site['alarm_notifications'] ? site['alarm_notifications'] : []
          let alarm_notifications = notifications.filter((notification) => alarm_notification_names.includes(notification.name))

          sites.push({
            id: this.randomID(),
            url: url,
            alarm_notifications: alarm_notifications,
          })
        }
      }

      if (json['credits_alarm_notifications']) {
        let credits_alarm_notification_names = json['credits_alarm_notifications']
        credits_alarm_notifications = notifications.filter((notification) => credits_alarm_notification_names.includes(notification.name))
      }
    }

    if(data != null && data !== "" && ('credits' in data) && ('usedCredits' in data)) {
      credits = data.credits
      used_credits = data.usedCredits
    }

    if (sites.length === 0) {
      sites.push({
        id: this.randomID(),
        url: "",
        alarm_notifications: []
      })
    }

    if (notifications.length === 0) {
      notifications.push({
        id: this.randomID(),
        name: "",
        pagerduty_integration_key: "",
        email: "",
        email_state: null
      })
    }

    this.setState({
      sites: sites,
      notifications: notifications,
      credits_alarm_notifications: credits_alarm_notifications,
      credits: credits,
      used_credits: used_credits
    });
  }


  saveSettings() {
    if(!this.state.signed) {
      return
    }

    let sites = this.state.sites.map((site) => {
      let r = {}
      if (site.url) {
        r.url = site.url
      }
      if (site.pagerduty_integration_key) {
        r.pagerduty_integration_key = site.pagerduty_integration_key
      }
      r.alarm_notifications = site.alarm_notifications.map((notification) => notification.name).filter((name) => name != null && name !== "")

      return r
    })
    sites = sites.filter((site) => (Object.keys(site).length !== 0))

    let notifications = this.state.notifications.map((notification) => {
      let r = {}
      if (notification.name) {
        r.name = notification.name
      }
      if (notification.pagerduty_integration_key) {
        r.pagerduty_integration_key = notification.pagerduty_integration_key
      }
      if (notification.email) {
        r.email = notification.email
      }
      return r
    })
    notifications = notifications.filter((site) => (Object.keys(site).length !== 0))


    let data = {
      sites: sites,
      notifications: notifications
    }
    if(this.state.credits_alarm_notifications) {
      data.credits_alarm_notifications = this.state.credits_alarm_notifications.map((notification) => notification.name).filter((name) => name != null && name !== "")
    }

    var json = JSON.stringify(data, null, 2)

    isSignedIn().then( (signed) => {
      if (signed) {
        this.setState({save_state: 'saving'})
        Auth.currentSession().then(session => {
            Service.setSettings(session.getIdToken().getJwtToken(), json);
            this.setState({save_state: 'saved'})
        })
      } else {
        this.setState({save_state: 'failed'})
      }
    })
  }

  verifyEmail(email_state, site, email) {
    isSignedIn().then( (signed) => {
      if (signed) {
        Auth.currentSession().then(session => {
          Service.verifyMail(session.getIdToken().getJwtToken(), email).then(r => {
            let sites = this.state.sites
            site.email_state = null
            this.setState({sites: sites})
          });
        })
      }
    })
  }

  verifyCreditsAlarmEmail(email_state, email) {
    isSignedIn().then( (signed) => {
      if (signed) {
        Auth.currentSession().then(session => {
          Service.verifyMail(session.getIdToken().getJwtToken(), email).then(r => {
            this.setState({credits_alarm_email_state: null})
          });
        })
      }
    })
  }


  handleChange(e) {
    this.setState({settings: e.target.value})
  }

  handleSiteChange(e, id) {
    let sites = this.state.sites
    let site = sites.filter((site) => site.id === id)[0]
    if (e.target.name === 'url') {
      site.url = e.target.value
    }
    this.setState({sites: sites, save_state: 'changed'})
  }

  handleSiteAlarmChange(e, id) {
    let sites = this.state.sites
    let site = sites.filter((site) => site.id === id)[0]

    let options = e.target.options;
    let selected_names = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selected_names.push(options[i].value);
      }
    }
    let selected_notifications = this.state.notifications.filter((notification) => selected_names.includes(notification.name))
    site.alarm_notifications = selected_notifications

    this.setState({sites: sites, save_state: 'changed'})
  }

  handleNotificationChange(e, id) {
    let notifications = this.state.notifications
    let notification = notifications.filter((site) => site.id === id)[0]
    if (e.target.name === 'name') {
      notification.name = e.target.value
    }
    if (e.target.name === 'pagerduty_integration_key') {
      notification.pagerduty_integration_key = e.target.value
    }
    if (e.target.name === 'email') {
      notification.email = e.target.value
      notification.email_state = null
    }
    this.setState({notifications: notifications, save_state: 'changed'})

    this.props.effect(() => {
      const use = async () => {
        const { initTE, Select } = await import('tw-elements');
        initTE({ Select }, { allowReinits: true });
      };
      use();
    }, []);
  }

  handleCreditsAlarmChange(e) {
    let options = e.target.options;
    let selected_names = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selected_names.push(options[i].value);
      }
    }

    let selected_notifications = this.state.notifications.filter((notification) => selected_names.includes(notification.name))

    this.setState({
      credits_alarm_notifications: selected_notifications,
      save_state: 'changed'})
  }

  addSite() {
    if(!this.state.signed) {
      return
    }

    if(this.state.sites.length === 5) {
      return
    }

    let sites = this.state.sites
    sites.push({
      id: this.randomID(),
      url: "",
      pagerduty_integration_key: ""
    })
    this.setState({sites: sites, save_state: 'changed'})
  }

  deleteSite(id) {
    if(!this.state.signed) {
      return
    }

    let sites = this.state.sites
    sites = sites.filter((site) => site.id !== id);

    if (sites.length === 0) {
      sites.push({
        id: this.randomID(),
        url: "",
        pagerduty_integration_key: ""
      })
    }

    this.setState({sites: sites, save_state: 'changed'})
  }

  addNotification() {
    if(!this.state.signed) {
      return
    }

    if(this.state.notifications.length === 5) {
      return
    }

    let notifications = this.state.notifications
    notifications.push({
      id: this.randomID(),
      name: "",
      pagerduty_integration_key: "",
      email: "",
      email_state: null
    })
    this.setState({notifications: notifications, save_state: 'changed'})
  }

  deleteNotification(id) {
    if(!this.state.signed) {
      return
    }

    let notifications = this.state.notifications
    notifications = notifications.filter((site) => site.id !== id);

    if (notifications.length === 0) {
      notifications.push({
        id: this.randomID(),
        name: "",
        pagerduty_integration_key: "",
        email: "",
        email_state: null
      })
    }

    this.setState({notifications: notifications, save_state: 'changed'})
  }

  pay() {
    if(!this.state.signed) {
      return
    }

    isSignedIn().then( (signed) => {
      if (signed) {
        Auth.currentSession().then(session => {
          Auth.currentAuthenticatedUser().then((user) => {
            if ('attributes' in user) {
              if ('email' in user.attributes) {
                let email = user.attributes.email
                let email_verified = ('email_verified' in user.attributes) ? user.attributes.email_verified : false
                if (email_verified) {

                  let seller = 178245;
                  let items = [{priceId: 'pri_01hbq4wa05vs58r6dkx5z26k3a'}];
                  let debug = this.isDebug()
                  if (debug) {
                    seller = 14747;
                    items = [{priceId: 'pri_01hb7g27f8twygp4fjsr67fb25'}];
                    window.Paddle.Environment.set("sandbox");
                  }
                  window.Paddle.Setup({
                    seller: seller,
                    debug: debug,
                    eventCallback: data => {
                      if (data.name === "checkout.completed") {
                        console.log(data.data.id);
                        console.log(data.data.transaction_id);
                        console.log(data.data.status);

                        Service.processPayment(session.getIdToken().getJwtToken(), data.data.transaction_id);

                        // Object { name: "checkout.completed", data: {…} }
                        // data: Object { id: "che_01hb7k8hbyfmkehjetk0vc01ry", transaction_id: "txn_01hb7k8gzjthdqxfxx9ez93fse", status: "completed", … }
                        // currency_code: "USD"
                        // custom_data: null
                        // customer: Object { id: "ctm_01hb7k35m513w93bm0tw1maw3w", email: "test@issiteok.com", address: {…}, … }
                        // id: "che_01hb7k8hbyfmkehjetk0vc01ry"
                        // items: Array [ {…} ]
                        // 0: Object { price_id: "pri_01hb7g27f8twygp4fjsr67fb25", product: {…}, quantity: 1, … }
                        // length: 1
                        // <prototype>: Array []
                        // payment: Object { method_details: {…} }
                        // method_details: Object { type: "card", card: {…} }
                        // card: Object { type: "visa", last4: "5556", expiry_month: 1, … }
                        // expiry_month: 1
                        // expiry_year: 2024
                        // last4: "5556"
                        // type: "visa"
                        // <prototype>: Object { … }
                        // type: "card"
                        // <prototype>: Object { … }
                        // <prototype>: Object { … }
                        // settings: Object { display_mode: "wide-overlay", theme: "light" }
                        // display_mode: "wide-overlay"
                        // theme: "light"
                        // <prototype>: Object { … }
                        // status: "completed"
                        // totals: Object { subtotal: 4.54, tax: 0.46, total: 5, … }
                        // balance: 0
                        // credit: 0
                        // discount: 0
                        // subtotal: 4.54
                        // tax: 0.46
                        // total: 5
                        // <prototype>: Object { … }
                        // transaction_id: "txn_01hb7k8gzjthdqxfxx9ez93fse"
                        // <prototype>: Object { … }
                        // name: "checkout.completed"

                        console.log(data);
                      }
                    }
                  });

                  let theme = "light";
                  if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
                    theme = "dark";
                  }

                  window.Paddle.Checkout.open({
                    settings: {
                      displayMode: "overlay",
                      theme: theme,
                      locale: "en"
                    },
                    items: items,
                    customer: {
                      email: email
                    }
                  });

                }
              }
            }
          }).catch(() => {})


        })
      }
    })

  }

  closeDeleteAccountModal() {
    this.setState({isDeleteAccountModalOpen: false})
  }

  deleteAccount() {
    this.closeDeleteAccountModal()

    isSignedIn().then( (signed) => {
      if (signed) {
        Auth.currentSession().then(session => {
          Service.deleteAccount(session.getIdToken().getJwtToken()).then(r => {
            this.signOut()
          });
        })
      }
    })
  }

  signOut() {
    Auth.signOut()
    this.setState({signed: false});

    if (this.state.context && this.state.context['AuthenticationLinks']) {
      for (const i in this.state.context['AuthenticationLinks']) {
        this.state.context['AuthenticationLinks'][i].setState({signed: false, loaded: true})
      }
    }

    if (this.state.context && this.state.context.getContext("SignInView")) {
      this.state.context.getContext("SignInView").setState({
        email: null,
        email_verified: null
      })
    }

    this.props.navigate("/signin")
  }


  render()
  {
    if (this.state.signed === null) {
      return (
        <div>
          <div className="container mx-auto space-y-4 p-4 px-8 py-6 max-w-[1000px] min-w-[320px]">
            Loading...
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="container mx-auto space-y-4 p-4 px-8 py-6 max-w-[1000px] min-w-[320px]">
            {this.state.signed === false ?
              <div className="bg-blue-100 dark:bg-blue-900 rounded-lg py-5 px-6 text-base text-blue-700 dark:text-blue-300" role="alert">
                You are not signed in and can not edit settings.<br/>
                These are settings for demonstration purposes.
              </div> : ""
            }
            { this.state.save_state == 'failed' ?
              <div className="bg-red-100 dark:bg-red-900 rounded-lg py-5 px-6 text-base text-red-700 dark:text-red-300" role="alert">
                Failed to save changes.<br/>
                Please make sure that you are logged in and retry.
              </div> : ""
            }
            { this.state.save_state == 'saved' ?
              <div className="bg-green-100 dark:bg-green-900 rounded-lg py-5 px-6 text-base text-green-700 dark:text-green-300" role="alert">
                Changes are saved.<br/>
                Expect about one minute delay before new data will appear at the
                <Link className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-200 pl-1 underline" to="/dashboard/daily/today">Dashboard</Link>.
              </div> : ""
            }

            <div className="w-10 block-inline mr-5 mt-4 ml-auto float-right">
              <Button className={this.getAddButtonStyle(this.state.signed === true ? (this.state.notifications.length === 5 ? "max_sites" : "normal") : "unsigned") } onClick={() => {
                this.addNotification()
              }}>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 12L18 12M12 6l0 12"/>
                </svg>
              </Button>
            </div>
            <div className="font-bold text-2xl lg:text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4">
              Notifications: {this.state.signed === false ? <span className="text-red-500 dark:text-red-500 align-super text-lg">[demo]</span> : ""}
            </div>

            {this.state.notifications.map((notification) =>
              <div className="group relative focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-600 rounded-md py-2 md:pl-10 ring-1 ring-neutral-300 dark:ring-neutral-600 shadow-sm">
                <div className="w-10 block-inline mr-5 mt-3 ml-auto float-right">
                  <Button className={this.getDeleteButtonStyle(this.state.signed === true ? "normal" : "unsigned")} onClick={() => {
                    this.deleteNotification(notification.id)
                  }}>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                  </Button>
                </div>
                <form>
                  <p className="px-5 mr-16">
                    <h1 className="mb-1">Notification name:</h1>
                    <input type="text" name="name" readOnly={!this.state.signed} value={notification.name} placeholder='name (like "default" or "oncall")' className="border-neutral-300 dark:border-neutral-400 dark:bg-black focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-600 rounded py-2 pl-3 ring-1 ring-neutral-300 dark:ring-neutral-600 shadow-sm"
                           onChange={(e) => {
                             this.handleNotificationChange(e, notification.id)
                           }}/>
                    <br/>
                  </p>
                  <p className="px-5 mr-16 mt-3 mb-1">
                    <h1 className="mb-1">PagerDuty integration key:</h1>
                    <input type="text" name="pagerduty_integration_key" readOnly={!this.state.signed} value={notification.pagerduty_integration_key} placeholder="example-integration-key" className="border-neutral-300 dark:border-neutral-400 dark:bg-black focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-600 rounded py-2 pl-3 ring-1 ring-neutral-300 dark:ring-neutral-600 shadow-sm"
                           onChange={(e) => {
                             this.handleNotificationChange(e, notification.id)
                           }}/>
                  </p>
                  <p className="px-5 mr-16 mt-3 mb-1">
                    <h1 className="mb-1">E-mail notifications:</h1>
                    <div className="w-full justify-between flex align-middle">
                      <input type="text" name="email" readOnly={!this.state.signed} value={notification.email} placeholder="mail@example.com" className="border-neutral-300 dark:border-neutral-400 dark:bg-black h-10 w-fit flex-grow focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none text-sm leading-6 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-600 rounded py-2 pl-3 ring-1 ring-neutral-300 dark:ring-neutral-600 shadow-sm"
                             onChange={(e) => {
                               this.handleNotificationChange(e, notification.id)
                             }}/>

                      <div className="w-32 block-inline pl-4 inline-block flex-none">
                        <Button className={this.getVerifyEmailButtonStyle(notification.email_state, notification, notification.email)} onClick={() => {
                          this.verifyEmail(notification.email_state, notification, notification.email)
                        }}>{this.getVerifyEmailButtonText(notification.email_state)}</Button>
                      </div>
                    </div>
                  </p>
                  <div className="bg-blue-100 dark:bg-blue-900 rounded-lg py-2 px-6 text-base text-blue-700 dark:text-blue-300 mt-2 mr-16 ml-2" role="alert">
                    You can set site address to "https://test.issiteok.com" to get test alarm to verify integrations.
                  </div>
                </form>
              </div>
            )}
            <div className="w-32">
              <Button className={this.getSaveButtonStyle(this.state.save_state)} onClick={() => {
                this.saveSettings()
              }}>{this.getSaveButtonText(this.state.save_state)}</Button>
            </div>


            <div className="w-10 block-inline mr-5 mt-4 ml-auto float-right">
              <Button className={this.getAddButtonStyle(this.state.signed === true ? (this.state.sites.length === 5 ? "max_sites" : "normal") : "unsigned") } onClick={() => {
                this.addSite()
              }}>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 12L18 12M12 6l0 12"/>
                </svg>
              </Button>
            </div>
            <div className="font-bold text-2xl lg:text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4">
              Sites to monitor: {this.state.signed === false ? <span className="text-red-500 dark:text-red-500 align-super text-lg">[demo]</span> : ""}
            </div>

            {this.state.sites.map((site) =>
              <div className="group relative focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-600 rounded-md py-2 md:pl-10 ring-1 ring-neutral-300 dark:ring-neutral-600 shadow-sm">
                <div className="w-10 block-inline mr-5 mt-3 ml-auto float-right">
                  <Button className={this.getDeleteButtonStyle(this.state.signed === true ? "normal" : "unsigned")} onClick={() => {
                    this.deleteSite(site.id)
                  }}>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                  </Button>
                </div>
                <form>
                  <p className="px-5 mr-16">
                    <h1 className="mb-1">Site address:</h1>
                    <input type="text" name="url" readOnly={!this.state.signed} value={site.url} placeholder="https://example.com" className="border-neutral-300 dark:border-neutral-400 dark:bg-black focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-600 rounded py-2 pl-3 ring-1 ring-neutral-300 dark:ring-neutral-600 shadow-sm"
                           onChange={(e) => {
                             this.handleSiteChange(e, site.id)
                           }}/>
                    <br/>
                  </p>
                  <p className="px-5 mr-16">
                  <h1 className="mb-1">In case of alarm notify:</h1>
                    {(this.state.notifications.filter((n) => (n.name != null && n.name !== "")).length === 0) ? "Please first create notification role." :
                      <NotificationSelect notifications={this.state.notifications} selected={site.alarm_notifications} onChange={(e) => this.handleSiteAlarmChange(e, site.id)} disabled={this.state.signed !== true} />
                    }
                  </p>
                </form>
              </div>
            )}
            <div className="w-32">
              <Button className={this.getSaveButtonStyle(this.state.save_state)} onClick={() => {
                this.saveSettings()
              }}>{this.getSaveButtonText(this.state.save_state)}</Button>
            </div>

            <div className="font-bold text-2xl lg:text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4">
              Credits: {this.state.signed === false ? <span className="text-red-500 dark:text-red-500 align-super text-lg">[demo]</span> : ""}
            </div>

            { this.state.credits && this.state.used_credits && (this.state.credits <= this.state.used_credits) ?
              <div
                className="bg-red-100 dark:bg-red-900 rounded-lg py-2 px-6 text-base text-red-700 dark:text-red-300 mt-2 mr-16 ml-2"
                role="alert">
                You are out of credits. This means that site monitoring is suspended.
              </div>
              : ""
            }
            { this.state.credits && this.state.used_credits && this.state.sites.length && (this.state.credits - this.state.used_credits < 60*24*14 * (this.state.sites.length > 1 ? this.state.sites.length : 1)) ?
              <div
                className="bg-yellow-100 dark:bg-yellow-900 rounded-lg py-2 px-6 text-base text-yellow-700 dark:text-yellow-300 mt-2 mr-16 ml-2"
                role="alert">
                With current settings your credits will be consumed in less than two seeks. And site monitoring will be suspended after that.
              </div>
              : ""
            }

            <div className="group relative focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-600 rounded-md py-2 md:pl-10 ring-1 ring-neutral-300 dark:ring-neutral-600 shadow-sm">
              <p>Available credits: <span name="credits">{this.state.credits - this.state.used_credits}</span></p>
              <p>This is about {Math.round((this.state.credits - this.state.used_credits)/60/24/3)/10} months of 1 minute checks for a single site.</p>
              {
                this.state.sites.length > 1 ?
                <p>Or it's
                  about {Math.round((this.state.credits - this.state.used_credits) / 60 / 24 / 3 / this.state.sites.length) / 10} months
                  of 1 minute checks for your settings with {this.state.sites.length} sites to monitor.</p> : ""
              }
            </div>

            <div className="group relative focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-600 rounded-md py-2 md:pl-10 ring-1 ring-neutral-300 dark:ring-neutral-600 shadow-sm">
              When running out of credits notify:
              <p className="px-5 mr-16 mt-3 mb-1">
                {(this.state.notifications.filter((n) => (n.name != null && n.name !== "")).length === 0) ? "Please first create notification role." :
                  <NotificationSelect notifications={this.state.notifications} selected={this.state.credits_alarm_notifications} onChange={this.handleCreditsAlarmChange} disabled={this.state.signed !== true} />
                }
              </p>
            </div>
            <div className="w-32">
              <Button className={this.getSaveButtonStyle(this.state.save_state)} onClick={() => {
                this.saveSettings()
              }}>{this.getSaveButtonText(this.state.save_state)}</Button>
            </div>


            <div className="bg-blue-100 dark:bg-blue-900 rounded-lg py-2 px-6 text-base text-blue-700 dark:text-blue-300 mt-2 mr-16 ml-2" role="alert">
              There is a single service plan option - simple one year of credits purchase. This will be enough to have a single domain checked once a minute for the whole year.
            </div>

            <div className="w-96">
              <Button className={this.getPayButtonStyle(this.state.signed === true ? "normal" : "unsigned")} onClick={() => {
                this.pay()
              }}>Buy one year of credits for 10$</Button>
            </div>

            <div className="font-bold text-2xl lg:text-2xl text-blue-500 dark:text-blue-500 leading-tight mt-4">
              Account deletion:
            </div>
            <div className="w-96">
              <Button className={this.getAccountDeleteButtonStyle(this.state.signed === true ? "normal" : "unsigned")} onClick={() => {
                if(!this.state.signed) {
                  return
                }
                this.state.isDeleteAccountModalOpen = true
                this.setState(this.state)
              }}>Permanently delete your account</Button>
            </div>
            <ConfirmationModal isOpen={this.state.isDeleteAccountModalOpen} onConfirm={this.deleteAccount} onCancel={this.closeDeleteAccountModal} title="Delete Account" confirmLabel="Delete" icon={red_alarm}>
              Are you sure you want to permanently delete your account?<br/> This action cannot be undone.
            </ConfirmationModal>

            <br/>
            <br/>
            <br/>
            <br/>

          </div>
        </div>
      );
    }

  }
};

function withUseEffect(Component) {
  return function WrappedComponent(props) {

    useEffect(() => {
      const use = async () => {
        const { initTE, Select } = await import('tw-elements');
        initTE({ Select }, { allowReinits: true });
      };
      use();
    }, []);

    const effect = useEffect;

    return <Component {...props} effect={effect}/>;
  }
}

function withNavigate(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }
}

export default withUseEffect(withNavigate(SettingsView));
