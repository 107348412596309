import React from "react";
import {Auth} from "aws-amplify";
import {Link, useNavigate} from "react-router-dom";
import Button from "./Button";

async function isSignedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

class AuthenticationLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id:props.id,
      context: props.context,
      loaded: false,
      signed: false
    };

    if(this.state.id && props.context) {
      if(! props.context.getContext('AuthenticationLinks')) {
        props.context.setContext('AuthenticationLinks', {})
      }
      props.context.getContext('AuthenticationLinks')[props.id] = this
    }
  }

  signOut() {
    Auth.signOut()
    this.setState({signed: false, loaded: true});

    if (this.state.context && this.state.context['AuthenticationLinks']) {
      for (const i in this.state.context['AuthenticationLinks']) {
        this.state.context['AuthenticationLinks'][i].setState({signed: false, loaded: true})
      }
    }

    if (this.state.context && this.state.context.getContext("SignInView")) {
      this.state.context.getContext("SignInView").setState({
        email: null,
        email_verified: null
      })
    }

    this.props.navigate("/signin")
  }

  componentDidMount() {
    isSignedIn().then( (signed) => {
      this.setState({ signed: signed, loaded: true});
    })
  }

  render() {
    return (
      this.state.loaded ?
        (this.state.signed ?
        <Button onClick={() => {this.signOut()}} className="rounded-lg px-4">Sign out</Button>
        :
        <Link to="/signin">
          <Button className="rounded-lg px-4">Sign&nbsp;in/up</Button>
        </Link>)
        : <div/>
    )
  }
}

export default function(props) {
  const navigate = useNavigate();
  return <AuthenticationLink {...props} navigate={navigate} />;
}
