import React, { createRef } from 'react';
import ok from '../assets/svgs/Dialog-accept.svg';
import red_alarm from '../assets/svgs/Emblem-important-red.svg';
import orange_alarm from '../assets/svgs/Emblem-important.svg';
import black_alarm from '../assets/svgs/Emblem-important-black.svg';
import grey_alarm from '../assets/svgs/Emblem-important-grey.svg';
import blue_question from '../assets/svgs/Emblem-question-blue.svg';

import { Manager, Reference, Popper } from 'react-popper'


import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import Tooltip from "./Tooltip";

const gradients = {
  purple: 'linear-gradient(to right, #9d50bb, #6e48aa)',
  orange: 'linear-gradient(to right, #ff4e50, #f9d423)',
  pink: 'linear-gradient(to right, #f857a6, #ff5858)',
  blue: 'linear-gradient(to right, #4b6cb7, #182848)',
  green: 'linear-gradient(to right, #134E5E, #71B280)',
};

export const Main = styled('main')`
  overflow: hidden;
  min-height: 30em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: ${(props) => gradients[props.gradient]};
  color: #ffffff;
  clip-path: polygon(99% 1%, 99% 95%, 50% 99%, 1% 95%, 1% 1%, 50% 5%);
  &:first-of-type {
    clip-path: polygon(99% 2%, 99% 97%, 50% 100%, 1% 97%, 1% 2%);
  }
  &:last-of-type {
    height: calc(100vh - 0.5em);
    clip-path: polygon(99% 0%, 99% 98%, 50% 98%, 1% 98%, 1% 0%, 50% 3%);
  }
`;

export const ReferenceBox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10em;
  height: 6em;
  background-color: #ffffff;
  color: #000000;
  border-radius: 4px;
  z-index: 1;
  position: relative;
  a {
    color: #000000;
  }
`;

export const ClickableReferenceBox = styled(ReferenceBox)`
  cursor: pointer;
`;

export const PopperBox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 6em;
  height: 6em;
  background-color: #232323;
  color: #ffffff;
  border-radius: 10px;
  padding: 0.5em;
  text-align: center;
  ${(props) => props.popperStyle};
`;

export const TransitionedPopperBox = styled(PopperBox)`
  transition: all 0.2s ease-out;
`;

export const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;
export const PoppersContainer = styled('div')`
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in 0.5s forwards;
`;

export const pulse = keyframes`
  0%   { box-shadow: 0 0 0 rgba(0, 0, 0, .2); }
  50%  { box-shadow: 0 0 0 4px rgba(0, 0, 0, .2); }
  100% { box-shadow: 0 0 0 rgba(0, 0, 0, .2); }
`;

export const PopperDot = styled('button')`
  cursor: pointer;
  border: 0;
  font-size: inherit;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #232323;
  animation: ${pulse} 2s ease infinite;
`;

export const Arrow = styled('div')`
  position: absolute;
  width: 3em;
  height: 3em;
  &[data-placement*='bottom'] {
    top: 0;
    left: 0;
    margin-top: -0.9em;
    &::before {
      border-width: 0 1.5em 1em 1.5em;
      border-color: transparent transparent #232323 transparent;
    }
  }
  &[data-placement*='top'] {
    bottom: 0;
    left: 0;
    margin-bottom: -2.9em;
    &::before {
      border-width: 1em 1.5em 0 1.5em;
      border-color: #232323 transparent transparent transparent;
    }
  }
  &[data-placement*='right'] {
    left: 0;
    margin-left: -1.9em;
    &::before {
      border-width: 1.5em 1em 1.5em 0;
      border-color: transparent #232323 transparent transparent;
    }
  }
  &[data-placement*='left'] {
    right: 0;
    margin-right: -1.9em;
    &::before {
      border-width: 1.5em 0 1.5em 1em;
      border-color: transparent transparent transparent#232323;
    }
  }
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;


// import Tooltips from "@material-tailwind/react/Tooltips";
// import TooltipsContent from "@material-tailwind/react/TooltipsContent";

const severity_order = ['critical', 'medium', 'low', 'ok', 'pending', 'missed'];

const error_mapping = {
  'bad-url': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Provided URL is not supported'
  },
  'unknown-scheme': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Scheme of the URL is not supported'
  },
  'not-ok': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Site did not return OK (200) code'
  },
  'failed-ssl': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Site failed SSL verification'
  },
  'failed-cert': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Failed to get certificate'
  },
  'future-cert': {
    source: 'issiteok',
    severity: 'medium',
    summary: 'Site certificate is in the future'
  },
  'past-cert': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Site certificate is expired'
  },
  'expiring-cert': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Certificate is expiring soon'
  },
  'expired-domain': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Site domain is expired'
  },
  'expiring-domain': {
    source: 'issiteok',
    severity: 'critical',
    summary: 'Domain is expiring soon'
  },
  missed: { source: 'issiteok', severity: 'missed', summary: 'No data' },
  pending: { source: 'issiteok', severity: 'pending', summary: 'No data yet' },
  ok: { source: 'issiteok', severity: 'ok', summary: 'All checks passed' }
};

class SiteCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: props.errors,
      time_label: props.time_label,
      data: props.data
    };
    this.ref = createRef();
  }

  componentWillReceiveProps(props) {
    this.setState({
      errors: props.errors,
      time_label: props.time_label,
      data: props.data
    });
  }

  getSeverity(data) {
    let severity = 'missed';
    let found_errors = false;
    let found_ok = false;
    data.forEach((check) => {
      let errors = Object.keys(check.errors);
      if (errors.length === 0) {
        found_ok = true
      }
      errors.forEach((error) => {
        found_errors = true
        if (
          severity_order.indexOf(severity) >
          severity_order.indexOf(error_mapping[error].severity)
        ) {
          severity = error_mapping[error].severity;
        }
      });
    });

    if (found_errors) {
      if(found_ok && severity === 'missed') {
        return 'ok'
      } else {
        return severity
      }
    } else {
      return 'ok'
    }
  }

  getIcon(severity) {
    if (severity === 'critical') return red_alarm;
    if (severity === 'medium') return orange_alarm;
    if (severity === 'missed') return grey_alarm;
    if (severity === 'pending') return blue_question;
    if (severity === 'ok') return ok;
    return red_alarm;
  }

  getErrorMessage(error) {
    return error_mapping[error] ? error_mapping[error].summary : error;
  }

  getErrorIcon(error) {
    if (error_mapping[error]) {
      return this.getIcon(error_mapping[error].severity);
    }
    return red_alarm;
  }

  randomID() {
    let rand = Math.random().toString(16).substr(2, 8);
    return rand
  }

  render() {
    return (
      <div className="inline-block group cursor-pointer px-0">
        <Tooltip icon={<img
            src={this.getIcon(this.getSeverity(this.state.data))}
            alt="severity"
            className="w-6"
            ref={this.ref}
          />}>
          <span className="absolute right-0 rounded-md shadow-md text-black dark:text-white bg-white dark:bg-black border border-gray-200 dark:border-gray-500 text-xs font-bold transition-all duration-100 p-2 text-left min-w-max">
            {this.state.data.map((check) => (
              <p>
                {check.label}
                {Object.keys(check.errors).length !== 0 ? (
                  Object.keys(check.errors).map((error) => (
                    <p>
                      <img
                        src={this.getErrorIcon(error)}
                        alt="alarm"
                        className="inline-block w-4"
                      />{' '}
                      {this.getErrorMessage(error)} : {check.errors[error]}
                    </p>
                  ))
                ) : (
                  <p>
                    <img
                      src={ok}
                      alt="ok"
                      className="inline-block w-4"
                    />{' '}
                    {this.getErrorMessage('ok')} : 1
                  </p>
                )}
              </p>
            ))}
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default SiteCheck;
