import { Fragment } from 'react'
import React, { createRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
//import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

class ConfirmationModal extends React.Component  {

  constructor(props) {
    super(props);
    //this.toggleModal = this.toggleModal.bind(this);
    this.cancelButtonRef = React.createRef();
  }

  render() {
    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={this.cancelButtonRef} onClose={this.props.onCancel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-gray-500 bg-opacity-75 dark:bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg bg-white dark:bg-black text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white dark:bg-black px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <img
                        src={this.props.icon}
                        alt="alarm"
                        className="inline-block w-12"
                      />
                      {/*<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">*/}
                      {/*  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />*/}
                      {/*</div>*/}
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
                          {this.props.title}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500 dark:text-gray-500">
                            {this.props.children}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 dark:bg-gray-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 dark:bg-red-600 px-3 py-2 text-sm font-semibold text-white dark:text-white shadow-sm hover:bg-red-500 dark:hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => this.props.onConfirm()}
                    >
                      {this.props.confirmLabel}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-black px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 sm:mt-0 sm:w-auto"
                      onClick={() => this.props.onCancel()}
                      ref={this.cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
}

export default ConfirmationModal;
