import React, { useState } from 'react';
import {Link, Route, Routes, useParams} from 'react-router-dom';

// components
import DailyResultsComponent from '../components/DailyResultsComponent';
import YearlyResultsComponent from '../components/YearlyResultsComponent';
import Button from '../components/Button';
import Header from "../components/Header";
import TimeUtils from "../utils/TimeUtils";

const types = ['daily', 'yearly'];

const DashboardView = (props) => {
  //const [type, setType] = useState(types[0]);
  const params = useParams();

  const updateThisYearlyResultsComponent = (() => {
    if (props.context.getContext('YearlyResultsComponent')) {
      props.context.getContext('YearlyResultsComponent').setState({
        loaded: false,
        data: null,
        signed: null,
        params: params,
        date: 'this-year',
        checks_by_month_and_site: {},
      }, () => {
        props.context.getContext('YearlyResultsComponent').componentDidMount();
      });
    }
  }).bind(this)

  const updateLastYearlyResultsComponent = (() => {
    if (props.context.getContext('YearlyResultsComponent')) {
      props.context.getContext('YearlyResultsComponent').setState({
        loaded: false,
        data: null,
        signed: null,
        params: params,
        date: TimeUtils.getLastYear(),
        checks_by_month_and_site: {},
      }, () => {
        props.context.getContext('YearlyResultsComponent').componentDidMount();
      });
    }
  }).bind(this)

  const updateTodayResultsComponent = (() => {
    if (props.context.getContext('DailyResultsComponent')) {
      props.context.getContext('DailyResultsComponent').setState({
        loaded: false,
        data: null,
        signed: null,
        params: params,
        date: 'today',
        checks_by_hour_and_site: {},
      }, () => {
        props.context.getContext('DailyResultsComponent').componentDidMount();
      });
    }
  }).bind(this)

  const updateAllResultsComponent = (() => {
    if (props.context.getContext('DailyResultsComponent')) {
      props.context.getContext('DailyResultsComponent').setState({
        loaded: false,
        data: null,
        signed: null,
        params: params,
        checks_by_hour_and_site: {},
      }, () => {
        props.context.getContext('DailyResultsComponent').componentDidMount();
      });
    }
    if (props.context.getContext('YearlyResultsComponent')) {
      props.context.getContext('YearlyResultsComponent').setState({
        loaded: false,
        data: null,
        signed: null,
        params: params,
        checks_by_month_and_site: {},
      }, () => {props.context.getContext('YearlyResultsComponent').componentDidMount();});
    }
  }).bind(this)

  return (
    <div>
      <div className="container mx-auto space-y-4 p-4 px-8 py-6 min-w-[320px] max-w-[1000px]">

        <div className="pl-4 py-4 float-right">
          <div className="inline-flex">
            <Button className="rounded-lg px-4 rounded-r-none">
              <Link to="/dashboard/daily/today" onClick={updateTodayResultsComponent}>Today</Link>
            </Button>
            <Button className="rounded-lg px-4 rounded-none">
              <Link to="/dashboard/yearly/this-year" onClick={updateThisYearlyResultsComponent}>This&nbsp;year</Link>
            </Button>
            <Button className="rounded-lg px-4 rounded-l-none">
              <Link to={"/dashboard/yearly/" + TimeUtils.getLastYear()} onClick={updateLastYearlyResultsComponent}>Last&nbsp;year</Link>
            </Button>

            &nbsp;&nbsp;
            <Button name="refresh" className="rounded-full px-2" onClick={updateAllResultsComponent}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
              </svg>
            </Button>
          </div>
        </div>
        {params.type === types[0] && <DailyResultsComponent context={props.context} date={params.date}/>}
        {params.type === types[1] && <YearlyResultsComponent context={props.context} date={params.date}/>}

        <br />
        {/*<div className="flex">*/}
        {/*  <div className="mr-6">*/}
        {/*    <Button onClick={() => setType(types[0])}>Daily Statistics</Button>*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <Button onClick={() => setType(types[1])}>Yearly Statistics</Button>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/**/}
        {/*{type === types[1] && <YearlyResultsComponent />}*/}
      </div>
    </div>
  );
};

export default DashboardView;
